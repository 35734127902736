import React from "react";
import GeneralModal from "../../GeneralModal/GeneralModal";
import TabelApplicant from "../TabelApplicant";
import { useRecoilValue } from "recoil";
import { openModalApplicant } from "../../../recoil/modal";
import { Box } from "@mui/material";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import { ModalProfileSeeker } from "../../ProfileSeeker/ModalProfileSeeker";

export const ModalApplicant = () => {
  const open = useRecoilValue(openModalApplicant);

  return (
    <Box className="card-data-grid">
      <GeneralModal open={open} title="Applicants">
        <Box>
          <TabelApplicant />
          <ModalProfileSeeker />
        </Box>
      </GeneralModal>
    </Box>
  );
};
