import React from "react";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { openModalInfo } from "../../../recoil/modal";
import GeneralModal from "../../GeneralModal/GeneralModal";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import { PostAdmin, PutAdmin } from "../../../services/api/Admins";
import {
  dataClickItem,
  methodRequestStatus,
  updateDataState,
} from "../../../recoil/global";
export default function ModalInfo() {
  const [open, setOpen] = useRecoilState(openModalInfo);
  const setUpdateData = useSetRecoilState(updateDataState);
  const methodRequest = useRecoilValue(methodRequestStatus);
  const dataItem = useRecoilValue(dataClickItem);
  // send request
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const infoData = {
      name: data.get("name"),
      email: data.get("email"),
      password: data.get("password"),
      mobile: data.get("mobile"),
    };

    // function api request
    if (methodRequest == "add") {
      PostAdmin(infoData)
        .then((res) => {
          if (res.status < 300) {
            console.log(res);
            toast.success(res?.message);
            handleClose();
            setUpdateData(Math.random());
          }
        })
        .catch((err) => {
          toast.error(<div>{err.response?.data?.errors[0]}</div>);
        });
    } else if (methodRequest == "edit") {
      const id = dataItem?.id;
      PutAdmin(id, infoData)
        .then((res) => {
          console.log(res);
          toast.success(res?.message);
          handleClose();
          setUpdateData(Math.random());
        })
        .catch((err) => {
          toast.error(<div>{err.response?.data?.errors[0]}</div>);
        });
    }
  };
  console.log(dataItem);
  // close modal
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <GeneralModal maxWidth="md" open={open} onSubmit={handleSubmit}>
      <Box sx={{ mx: 2, my: 2 }}>
        <Box sx={{ mx: 2, mt: 1, display: "flex", alignItems: "center" }}>
          <ContactEmergencyIcon color={"primary"} />
          <Typography variant="h5" fontSize={18} fontWeight={500}>
            &nbsp; Add Admin
          </Typography>
        </Box>

        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                fullWidth
                name="name"
                required
                label="Name"
                type="text"
                id="name"
                defaultValue={dataItem?.name}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                fullWidth
                required
                name="email"
                label="Email"
                type="text"
                id="email"
                defaultValue={dataItem?.email}
              />
            </Box>
          </Grid>
          {methodRequest == "add" && (
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  mx: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  required
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                key={dataItem?.mobile}
                size="small"
                margin="normal"
                fullWidth
                required
                name="mobile"
                label="Mobile"
                type="phone"
                id="mobile"
                defaultValue={dataItem?.mobile}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </GeneralModal>
  );
}
