import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { openModalInfo } from "../../../recoil/modal";
import {
  dataClickItem,
  methodRequestStatus,
  updateDataState,
} from "../../../recoil/global";
import { selectedJobFieldState } from "../../../recoil/select";
import GeneralModal from "../../GeneralModal/GeneralModal";
import BusinessIcon from "@mui/icons-material/Business";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import SelectJobField from "../../Selects/SelectJobField";
import { PostJobTitle, PutJobTitle } from "../../../services/api/jobTitle";

export default function ModalInfo() {
  const [open, setOpen] = useRecoilState(openModalInfo);
  const [selectedJobField, setSelectedJobField] = useRecoilState(
    selectedJobFieldState
  );

  const dataItem = useRecoilValue(dataClickItem);
  const setUpdateData = useSetRecoilState(updateDataState);
  const methodRequest = useRecoilValue(methodRequestStatus);

  // remove old data
  useEffect(() => {
    setSelectedJobField(null);
  }, [open]);

  // send request
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const infoData = {
      job_field_id: selectedJobField?.id,
      name_ar: data.get("name_ar"),
      name_en: data.get("name_en"),
    };

    // function api request
    if (methodRequest === "add") {
      PostJobTitle(infoData)
        .then((res) => {
          if (res.status < 300) {
            console.log(res);
            toast.success(res?.message);
            handleClose();
            setUpdateData(Math.random());
          }
        })
        .catch((err) => {
          toast.error(<div>{err.response?.data?.errors[0]}</div>);
        });
    } else {
      const idItem = dataItem?.id;
      PutJobTitle(idItem, infoData)
        .then((res) => {
          console.log(res);
          toast.success(res?.message);
          handleClose();
          setUpdateData(Math.random());
        })
        .catch((err) => {
          toast.error(<div>{err.response?.data?.errors[0]}</div>);
        });
    }
  };

  // close modal
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <GeneralModal maxWidth="md" open={open} onSubmit={handleSubmit}>
      <Box sx={{ mx: 2, my: 2 }}>
        <Typography
          sx={{ mx: 2, mt: 1 }}
          variant="h5"
          fontSize={18}
          fontWeight={500}
        >
          <ContactEmergencyIcon color={"primary"} />
          &nbsp; Job Title Information
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                sx={{ minWidth: 300 }}
                size="small"
                margin="normal"
                required
                fullWidth
                name="name_en"
                label="Name EN"
                type="text"
                id="name_en"
                defaultValue={dataItem?.name_en}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                fullWidth
                required
                name="name_ar"
                label="Name AR"
                type="text"
                id="name_ar"
                defaultValue={dataItem?.name_ar}
              />
            </Box>
          </Grid>
        </Grid>
        <Typography
          sx={{ mx: 2, mt: 1, display: "flex", alignItems: "center" }}
          variant="h5"
          fontSize={18}
          fontWeight={500}
        >
          <BusinessIcon color={"primary"} />
          &nbsp; Field
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box noValidate sx={{ width: "100%" }}>
                <Box noValidate sx={{ width: "100%", minHeight: 150 }}>
                  <SelectJobField req={true} />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </GeneralModal>
  );
}
