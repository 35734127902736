import { deleteData, getData, postData, putData } from "..";

// get Job Title
export const JobTitleData = async (skip, take, search) => {
  try {
    let searchItem = search == undefined ? "" : search;
    const request = await getData(
      `/admin/job-title?skip=${skip}&take=${take}&search=${searchItem}`
    ); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
  }
};

// post Job Title
export const PostJobTitle = async (data) => {
  try {
    const request = await postData(`/admin/job-title`, data); // Handle the data
    return request;
  } catch (error) {
    throw error;
  }
};

// post Job Title
export const PutJobTitle = async (id, data) => {
  try {
    const request = await putData(`/admin/job-title/${id}`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
  }
};

// delete Job Title
export const DeleteJobTitle = async (id) => {
  try {
    const request = await deleteData(`/admin/job-title/${id}`); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};
