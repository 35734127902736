import { atom } from "recoil";

// save data Select
export const dataSelectProvinceState = atom({
  key: "dataSelectProvinceState",
  default: null,
});

// save data Province State
export const saveProvinceState = atom({
  key: "saveProvinceState",
  default: null,
});

// save data Select
export const dataSelectCityState = atom({
  key: "dataSelectCityState",
  default: null,
});

// save data city
export const saveCityState = atom({
  key: "saveCityState",
  default: null,
});

// save select item cities
export const selectedCityState = atom({
  key: "selectedCityState",
  default: false,
});

// save select item
export const selectedItemState = atom({
  key: "selectedItemState",
  default: null,
});

// save data Select
export const dataSelectCompanyState = atom({
  key: "dataSelectCompanyState",
  default: null,
});

// save data Select
export const dataSelectWorkTypeState = atom({
  key: "dataSelectWorkTypeState",
  default: null,
});

// save data Work Type
export const saveWorkTypeState = atom({
  key: "saveWorkTypeState",
  default: null,
});
// save admin notes status select
export const dataSelectAdminNotesStatusState = atom({
  key: "dataSelectAdminNotesStatusState",
  default: null,
});
// save admin notes status
export const saveAdminNotesStatusState = atom({
  key: "saveAdminNotesStatusState",
  default: null,
});
// save data Select
export const dataSelectGenderState = atom({
  key: "dataSelectGenderState",
  default: null,
});

// save data Select
export const dataSelectEducationsDegreeState = atom({
  key: "dataSelectEducationsDegreeState",
  default: null,
});

// save data Educations Degree
export const saveEducationsDegreeState = atom({
  key: "saveEducationsDegreeState",
  default: null,
});

// save data Select
export const dataSelectJobTitleState = atom({
  key: "dataSelectJobTitleState",
  default: null,
});

// save selectt data
export const dataSelectState = atom({
  key: "dataSelectState",
  default: false,
});

// selected state id jop field in job title
export const selectedJobFieldState = atom({
  key: "selectedJobFieldState",
  default: null,
});

// selected state Status
export const selectedStatusState = atom({
  key: "selectedStatusState",
  default: null,
});

// selected state curency
export const selectedCurencyState = atom({
  key: "selectedCurencyState",
  default: "IQD",
});

// selected state curency
export const selectedExpiredState = atom({
  key: "selectedExpiredState",
  default: "",
});

export const selectedEmployerFieldState = atom({
  key: "selectedEmployerFieldState",
  default: null,
});

export const selectedUsersState = atom({
  key: "selectedUsersState",
  default: [],
});
