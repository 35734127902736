import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useRecoilState } from 'recoil';
import { selectedExpiredState } from '../../recoil/select';

const SelectExpired = () => {
    const [selectedExpired, setSelectedExpired] = useRecoilState(selectedExpiredState);

    const handleChange = (event) => {
        setSelectedExpired(event.target.value);
    };

    const data = [{
        title: 'All',
        value: ''
    },
    {
        title: 'Expired',
        value: true
    }, {
        title: 'Not Expired',
        value: false
    }];

    return (
        <FormControl fullWidth margin="normal" sx={{ minWidth: 300 }}>
            <InputLabel id="expired-label" sx={{ background: '#fff' }}>
                Expired
            </InputLabel>
            <Select
                size='small'
                sx={{ minWidth: 300 }}
                labelId="expired-label"
                id="expired-select"
                onChange={handleChange}
                value={selectedExpired}
            >
                {data?.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                        {item.title}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectExpired;
