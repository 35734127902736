import { useState, useEffect } from "react";
import { Box, Avatar, Chip } from "@mui/material";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { DataGridTabel } from "../DataGridTabel/DataGridTabel";
import { ColumnText } from "../DataGridTabel/ColumnText";
import { ColumnButton } from "../DataGridTabel/ColumnButton";
import { GetJobApplicantData } from "../../services/api/jobs";
import ModalInfo from "./Modal/ModalInfo";
import { dataClickItem, updateDataState } from "../../recoil/global";
import { openModalProfileSeeketState } from "../../recoil/modal";
import ModalDelete from "./Modal/ModalDelete";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import { toast } from "react-toastify";
const TabelApplicant = () => {
  const [data, setData] = useState({
    data: [],
    loading: false,
    dialog: false,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      search: "",
    },
  });
  const updateData = useRecoilValue(updateDataState);
  const [dataItem, setDataItem] = useRecoilState(dataClickItem);
  const [openModalProfileSeeket, setOpenModalProfileSeeket] = useRecoilState(
    openModalProfileSeeketState
  );

  const handelOpenModalProfileSeeker = (data) => {
    setOpenModalProfileSeeket(true);
    setDataItem(data);
  };

  const handleOpenURL = (url) => {
    if (url) {
      let completeURL = url;
      // Check if the URL contains a protocol, if not, add 'https://' as a default
      if (!url?.includes("http://") && !url?.includes("https://")) {
        completeURL = `https://${url}`;
      }
      const newWindow = window.open();
      newWindow.location.href = completeURL;
    } else {
      toast.error(<div>CV does not exist</div>);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      headerClassName: "employer-grid--header",
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <Avatar src={params.row?.seeker?.profile_pic} sizes={"small"} />
            <Box>
              <ColumnText
                text={params.row?.seeker?.name}
                variant="subtitle1"
                fontWeight="bold"
              />
              <ColumnText text={params.row?.seeker?.mobile} color={"gray"} />
            </Box>
          </Box>
        );
      },
    },
    {
      field: "skills",
      headerName: "Skills",
      flex: 1,
      minWidth: 250,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={params.row?.seeker?.skills}
              variant="subtitle2"
              fontWeight="bold"
            />
            {/* <ColumnText text={params.row?.seeker.mobile} name_ar="caption" color={"gray"} /> */}
          </Box>
        );
      },
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
      minWidth: 195,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <ColumnText
            text={`${params.row.seeker?.city?.province?.name_en} - ${params.row.seeker?.city?.name_en}`}
            variant="subtitle2"
            fontWeight="bold"
          />
        );
      },
    },
    {
      field: "applicant_stage",
      headerName: "Stage",
      flex: 1,
      minWidth: 100,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <Chip
            label={
              params?.row?.applicant_stage?.name_en
                ? params?.row?.applicant_stage?.name_en
                : "N/A"
            }
            color={
              params?.row?.applicant_stage?.name_en === "apply"
                ? "success"
                : "default"
            }
            variant="outlined"
          />
        );
      },
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      minWidth: 100,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnButton
              title="User"
              color={"info"}
              onClick={() => handelOpenModalProfileSeeker(params.row)}
              icon={<AccountBoxIcon color="info" />}
            />
            <ColumnButton
              title="Download CV"
              color={"info"}
              onClick={() => handleOpenURL(params?.row?.seeker?.cv_path)}
              icon={<ContactPageIcon color="warning" />}
            />
          </Box>
        );
      },
    },
  ];

  const fetchData = () => {
    setData((old) => ({
      ...old,
      loading: true,
    }));

    GetJobApplicantData(
      dataItem?.id,
      data.filters.skip,
      data.filters.take,
      data.filters.search
    )
      .then((data) => {
        console.log("Dtest", data);
        const updatedData = data.data.map((item, index) => {
          if (!item.id) {
            return { ...item, id: index };
          }
          return item;
        });
        setData((old) => ({
          ...old,
          data: updatedData,
          total: data?.total,
          loading: false,
        }));
      })
      .catch(() => {
        setData({ ...data, loading: false });
      });
  };

  let filters = data.filters.page || data.filters.take || data.filters.skip;

  useEffect(() => {
    fetchData();
    console.log("test", data);
  }, [filters, updateData]);
  useEffect(() => {
    return () => {
      setData({
        data: [],
        loading: false,
        dialog: false,
        filters: {
          skip: 0,
          take: 25,
          page: 0,
          search: "",
        },
      });
      setOpenModalProfileSeeket(false);
      setDataItem(null);
    };
  }, []);
  return (
    <>
      <Box sx={{ overflow: "auto" }}>
        <DataGridTabel columns={columns} data={data} setData={setData} />
        {/* Modal */}
        <ModalInfo />
        <ModalDelete />
      </Box>
    </>
  );
};

export default TabelApplicant;
