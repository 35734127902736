import React from "react";
import {
  Avatar,
  Box,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Facebook, LinkedIn } from "@mui/icons-material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import TranslateIcon from "@mui/icons-material/Translate";
import { CardExperience } from "./CardExperience";
import { CardCertificate } from "./CardCertificate";
import { CardEducation } from "./CardEducation";
import { CardDescription } from "./CardDescription";
import LanguageIcon from "@mui/icons-material/Language";
import { Link } from "react-router-dom";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import PublicIcon from "@mui/icons-material/Public";
import FlightIcon from "@mui/icons-material/Flight";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseIcon from "@mui/icons-material/Close";
import Skills from "./Skills";
import { toast } from "react-toastify";
export const ProfileSeeker = (props) => {
  const handleOpenURL = (url) => {
    if (url) {
      let completeURL = url;
      // Check if the URL contains a protocol, if not, add 'https://' as a default
      if (!url.includes("http://") && !url.includes("https://")) {
        completeURL = `https://${url}`;
      }
      const newWindow = window.open();
      newWindow.location.href = completeURL;
    } else {
      toast.error(<div>CV does not exist</div>);
    }
  };

  return (
    <Box container>
      <Box sx={{ background: "#ecf0f1" }}>
        <Grid container sx={{ pb: 4, maxWidth: 1400, m: "auto" }}>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 4,
              maxHeight: 194,
            }}
          >
            <Avatar
              src={props?.data?.profile_pic}
              sx={{ width: 180, height: 180 }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={9}
            sx={{
              mt: 4,
            }}
          >
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h5"
                  fontWeight={600}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "start" },
                  }}
                >
                  {props?.data?.name}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "start" },
                    textTransform: "capitalize",
                  }}
                >
                  {props?.data?.gender}
                </Typography>
                <Box
                  sx={{
                    mt: 1,
                    display: "flex",
                    justifyContent: { xs: "center", md: "start" },
                  }}
                >
                  <Chip label="Seeker" size="small" color="warning" />
                </Box>
                <Box
                  sx={{
                    mt: 1,
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      display: "flex",
                      justifyContent: { xs: "center", md: "start" },
                    }}
                  >
                    <LocalPhoneIcon />
                    &nbsp;
                    {props?.data?.mobile}
                  </Typography>
                  <Typography
                    variant="body1"
                    mt={1}
                    sx={{
                      display: "flex",
                      justifyContent: { xs: "center", md: "start" },
                    }}
                  >
                    <EmailIcon />
                    &nbsp;
                    {props?.data?.email}
                  </Typography>
                  <Typography
                    variant="body1"
                    mt={1}
                    sx={{
                      display: "flex",
                      justifyContent: { xs: "center", md: "start" },
                    }}
                  >
                    <MyLocationIcon />
                    &nbsp;
                    {props?.data?.city?.province?.name_en +
                      " - " +
                      props?.data?.city?.name_en}
                  </Typography>
                  <Typography
                    variant="body1"
                    mt={1}
                    sx={{
                      display: "flex",
                      justifyContent: { xs: "center", md: "start" },
                    }}
                  >
                    <TranslateIcon />
                    &nbsp;
                    <Box sx={{ display: "flex", gap: 1 }}>
                      {props?.data?.languages?.map((item, index) => {
                        return (
                          <Chip key={index} label={item?.name} size="small" />
                        );
                      })}
                    </Box>
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      display: "flex",
                      justifyContent: { xs: "center", md: "start" },
                    }}
                  >
                    <DirectionsCarFilledIcon />
                    Driving License &nbsp;
                    {(props?.data?.has_driver_license == 1 && (
                      <DoneAllIcon />
                    )) || <CloseIcon />}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      display: "flex",
                      justifyContent: { xs: "center", md: "start" },
                    }}
                  >
                    <FlightIcon />
                    Passport &nbsp;
                    {(props?.data?.has_passport == 1 && <DoneAllIcon />) || (
                      <CloseIcon />
                    )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    justifyContent: { xs: "center", md: "start" },
                    mt: { xs: 3, md: 0 },
                  }}
                >
                  {props?.data?.facebook && (
                    <Tooltip title="Facebook">
                      <IconButton
                        size="small"
                        sx={{ color: "#2980b9", border: ".5px solid #2980b9" }}
                        onClick={() => handleOpenURL(props?.data?.facebook)}
                      >
                        <Facebook />
                      </IconButton>
                    </Tooltip>
                  )}
                  {props?.data?.website && (
                    <Tooltip title="Website">
                      <IconButton
                        size="small"
                        sx={{ color: "#e74c3c", border: ".5px solid #e74c3c" }}
                        onClick={() => handleOpenURL(props?.data?.website)}
                      >
                        <LanguageIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {props?.data?.linkedin && (
                    <Tooltip title="LinkdIn">
                      <IconButton
                        size="small"
                        sx={{ color: "#3498db", border: ".5px solid #3498db" }}
                        onClick={() => handleOpenURL(props?.data?.linkedin)}
                      >
                        <LinkedIn />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title="Download CV">
                    <IconButton
                      size="small"
                      sx={{ color: "#8e44ad", border: ".5px solid #8e44ad" }}
                      onClick={() => handleOpenURL(props?.data?.cv)}
                    >
                      <ContactPageIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Show Profile">
                    <Link to={`/profile-seeker/${props?.data?.id}`}>
                      <IconButton
                        size="small"
                        sx={{ color: "#f39c12", border: ".5px solid #f39c12" }}
                        // onClick={handleClose}
                      >
                        <RemoveRedEyeIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                </Box>
                <Box>
                  <CardDescription data={props?.data} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Grid container sx={{ maxWidth: 1260, m: "auto" }}>
        <Grid
          item
          xs={12}
          md={7}
          sx={{ mt: 3, borderRight: "2px solid #ecf0f1" }}
        >
          <Box ml={2} mr={1}>
            {props?.data?.experiences?.length > 0 && (
              <Box>
                <Typography variant="h5" fontWeight={600}>
                  Experience
                </Typography>
                {props?.data?.experiences.map((item) => {
                  return (
                    <CardExperience
                      jobTitle={item.job_title_name_en}
                      companyName={item.company_name}
                      startDate={item.start_date}
                      endDate={item?.end_date}
                    />
                  );
                })}
              </Box>
            )}
            <Box>
              {props?.data?.skills && <Skills skills={props?.data?.skills} />}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={5} sx={{ mt: 3 }}>
          {props?.data?.education?.length > 0 && (
            <Box mx={3}>
              <Typography variant="h5" fontWeight={600}>
                Education
              </Typography>
              {props?.data?.education.map((item) => {
                return (
                  <CardEducation
                    name={item.name}
                    field={item.field}
                    educationDegree={item.education_degree?.name_en}
                    date={item.year}
                    grade={item?.grade}
                  />
                );
              })}
            </Box>
          )}
          {props?.data?.certificates?.length > 0 && (
            <Box mt={3} ml={3} mr={1}>
              <Typography variant="h5" fontWeight={600}>
                Certificate
              </Typography>
              {props?.data?.certificates.map((item) => {
                return (
                  <CardCertificate
                    resource={item.resource}
                    name={item.name}
                    date={item.expire}
                    description={item?.description}
                  />
                );
              })}
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
