import { useEffect } from "react";
import { Avatar, Box } from "@mui/material";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { DataGridTabel } from "../DataGridTabel/DataGridTabel";
import { ColumnText } from "../DataGridTabel/ColumnText";
import { ColumnButton } from "../DataGridTabel/ColumnButton";
import { HeaderGridTabel } from "../DataGridTabel/HeaderGridTabel";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ModalInfo from "./Modal/ModalInfo";
import {
  dataClickItem,
  methodRequestStatus,
  openFilterState,
  updateDataState,
} from "../../recoil/global";
import {
  confirmModalState,
  openModalDelete,
  openModalInfo,
} from "../../recoil/modal";
import ModalDelete from "./Modal/ModalDelete";
import { useState } from "react";
import {
  GetEmployerData,
  PostChangeStatusEmployer,
} from "../../services/api/employer";
import { toast } from "react-toastify";
import { ChangeStatusByChips } from "./ChangeStatusByChips";
import { dataTabelState } from "../../recoil/Tabel";
import Filters from "./Filters";
import {
  dataSelectProvinceState,
  selectedStatusState,
} from "../../recoil/select";

export const Tabel = () => {
  const [data, setData] = useRecoilState(dataTabelState);
  const [emailSearch, setEmailSearch] = useState(null);
  const [phoneSearch, setPhoneSearch] = useState(null);
  const selectedStatus = useRecoilValue(selectedStatusState);
  const dataSelectProvince = useRecoilValue(dataSelectProvinceState);
  const state = useRecoilValue(openFilterState);
  const updateData = useRecoilValue(updateDataState);
  const resetConfirm = useResetRecoilState(confirmModalState);
  const setDataItem = useSetRecoilState(dataClickItem);
  const setOpenModal = useSetRecoilState(openModalInfo);
  const setOpenDelete = useSetRecoilState(openModalDelete);
  const setMethodRequest = useSetRecoilState(methodRequestStatus);

  useEffect(() => {
    setDataItem(null);
  }, [state]);

  const handelEdit = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("edit");
  };

  const handelDelete = (data) => {
    setOpenDelete(true);
    setDataItem(data);
    setMethodRequest("delete");
  };

  //change employer status function
  const changeEmployerStatus = (params, status) => {
    let dataInfo = {
      user_id: params.id,
      rejection_note: "No reason",
      status: status.id,
    };
    PostChangeStatusEmployer(dataInfo)
      .then((res) => {
        toast.success(res?.message);
        fetchData();
        resetConfirm();
      })
      .catch((err) => {
        toast.error(<div>{err.response?.data?.errors[0]}</div>);
      });
  };

  const columns = [
    {
      field: "company_name",
      headerName: "Company Name",
      headerClassName: "employer-grid--header",
      flex: 1,
      minWidth: 195,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <Avatar src={params.row?.profile_pic} sizes={"small"} />
            <ColumnText
              text={params.row?.company_name}
              variant="subtitle2"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      headerClassName: "employer-grid--header",
      flex: 1,
      minWidth: 195,
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={params.row?.name}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "employer-grid--header",
      flex: 1,
      minWidth: 265,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={params.row?.email}
              variant="subtitle2"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      minWidth: 195,
      headerClassName: "employer-grid--header",
      sortable: false,
      renderCell: (params) => {
        var t = "";
        if (params.row?.city?.province?.name_en && params.row?.city?.name_en) {
          t = `${params.row?.city?.province?.name_en} - ${params.row?.city?.name_en}`;
        } else if (
          params.row?.city?.province?.name_en &&
          !params.row?.city?.name_en
        ) {
          t = params.row?.city?.province?.name_en;
        } else if (
          !params.row?.city?.province?.name_en &&
          params.row?.city?.name_en
        ) {
          t = params.row?.city?.name_en;
        } else {
          t = "N/A";
        }
        return <ColumnText text={t} variant="subtitle2" fontWeight="bold" />;
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 120,
      headerClassName: "employer-grid--header",

      renderCell: (params) => {
        return (
          <ChangeStatusByChips
            id={params.row.id}
            status={params.row.status}
            onChangeStatus={(item) => {
              changeEmployerStatus(params.row, item);
              console.log(item);
            }}
          />
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created at",
      flex: 1,
      minWidth: 170,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <ColumnText
            text={new Date(params.row?.created_at).toLocaleString()}
            variant="subtitle2"
            fontWeight="bold"
          />
        );
      },
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      minWidth: 120,
      sortable: false,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnButton
              onClick={() => handelEdit(params.row)}
              type={"edit"}
            />
            <ColumnButton
              onClick={() => handelDelete(params.row)}
              type={"delete"}
            />
          </Box>
        );
      },
    },
  ];

  const fetchData = () => {
    setData((old) => ({
      ...old,
      loading: true,
    }));
    let selectProvince = dataSelectProvince?.id ? dataSelectProvince?.id : "";
    GetEmployerData(
      data.filters.skip,
      data.filters.take,
      data.filters.search,
      emailSearch,
      phoneSearch,
      selectedStatus,
      selectProvince
    )
      .then((res) => {
        console.log("Dtest", res);
        setData((old) => ({
          ...old,
          data: res?.data,
          total: res?.total,
          loading: false,
        }));
      })
      .catch(() => {
        setData({ ...data, loading: false });
      });
  };

  let filters = data.filters.page || data.filters.take || data.filters.skip;

  useEffect(() => {
    fetchData();
  }, [filters, updateData]);

  useEffect(() => {
    return () => {
      setData({
        data: [],
        loading: false,
        dialog: false,
        filters: {
          skip: 0,
          take: 25,
          page: 0,
          search: "",
        },
      });
    };
  }, []);
  return (
    <>
      <Box m={2}>
        <HeaderGridTabel
          title="Employer"
          icon={<HomeWorkIcon size={25} color={"primary"} />}
          btn={"Add Employer"}
          fetchData={fetchData}
          isFilter={true}
        />
        <Box mt={2}>
          <DataGridTabel columns={columns} data={data} setData={setData} />
        </Box>
        {/* Modal */}
        <ModalInfo />
        <ModalDelete />
        {/* Fillter */}
        <Filters
          emailSearch={emailSearch}
          setEmailSearch={setEmailSearch}
          phoneSearch={phoneSearch}
          setPhoneSearch={setPhoneSearch}
          fetchData={fetchData}
        />
      </Box>
    </>
  );
};

export default Tabel;
