import { deleteData, getData, postData, putData } from "..";

// get JobField
export const GetJobFieldData = async (skip, take, search) => {
    try {
        const request = await getData(`/admin/job-field?skip=${skip}&take=${take}&search=${search}`); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};

// post JobField
export const PostJobField = async (data) => {
    try {
        const request = await postData(`/admin/job-field`, data); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};

// post JobField
export const PutJobField = async (id, data) => {
    try {
        const request = await putData(`/admin/job-field/${id}`, data); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};


// delete JobField
export const DeleteJobField = async (id) => {
    if (id) {
        try {
            const request = await deleteData(`/admin/job-field/${id}`); // Handle the data
            return request;
        } catch (error) {
            console.error(error); // Handle the error
            throw error;
        }
    }
};
