import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { openModalInfo } from "../../../recoil/modal";
import {
  dataClickItem,
  methodRequestStatus,
  updateDataState,
} from "../../../recoil/global";
import { dataSelectProvinceState } from "../../../recoil/select";
import GeneralModal from "../../GeneralModal/GeneralModal";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  postCvTemplate,
  putCvTemplate,
} from "../../../services/api/cvTemplate";
import ArticleIcon from "@mui/icons-material/Article";
import DeleteIcon from "@mui/icons-material/Delete";
export default function ModalInfo() {
  const [open, setOpen] = useRecoilState(openModalInfo);
  const dataItem = useRecoilValue(dataClickItem);
  const setUpdateData = useSetRecoilState(updateDataState);
  const methodRequest = useRecoilValue(methodRequestStatus);
  const setDataSelectProvince = useSetRecoilState(dataSelectProvinceState);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [removeImage, setRemoveImage] = useState(0);
  // remove old data
  useEffect(() => {
    setDataSelectProvince(null);
    setImagePreview(null);
    setRemoveImage(0);
  }, [open]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };
  const handleRemoveImage = () => {
    setSelectedFile(null);
    if (
      imagePreview &&
      (!dataItem?.thumbnail_path || dataItem?.thumbnail_path)
    ) {
      setImagePreview(null);
    } else if (dataItem?.thumbnail_path) {
      setRemoveImage(1);
    }
  };
  // send request
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const formData = new FormData();
    formData.append("name", data.get("name"));
    formData.append("file_path", data.get("file_path"));

    // function api request
    if (methodRequest === "add") {
      selectedFile && formData.append("attachment", selectedFile);
      // function api request
      postCvTemplate(formData)
        .then((res) => {
          if (res.status < 300) {
            console.log(res);
            toast.success(res?.message);
            handleClose();
            setUpdateData(Math.random());
            setSelectedFile(null);
          }
        })
        .catch((err) => {
          toast.error(<div>{err.response?.data?.errors[0]}</div>);
        });
    } else {
      const idItem = dataItem?.id;
      formData.append("_method", "PUT");
      selectedFile && formData.append("attachment", selectedFile);
      removeImage && formData.append("remove_attachment", removeImage);
      putCvTemplate(idItem, formData)
        .then((res) => {
          console.log(res);
          toast.success(res?.message);
          handleClose();
          setUpdateData(Math.random());
          setRemoveImage(0);
        })
        .catch((err) => {
          toast.error(<div>{err.response?.data?.errors[0]}</div>);
        });
    }
  };

  // close modal
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <GeneralModal maxWidth="md" open={open} onSubmit={handleSubmit}>
      <Box sx={{ mx: 2, my: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ArticleIcon color={"primary"} />
          <Typography
            sx={{ paddingInlineStart: "8px" }}
            variant="h5"
            fontSize={17}
            fontWeight={600}
          >
            Template Info
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 1.5,
              }}
            >
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                name="name"
                label="Name"
                type="text"
                id="name"
                defaultValue={dataItem?.name}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: { xs: 1.5, sm: 3 },
              }}
            >
              <TextField
                size="small"
                margin="normal"
                fullWidth
                required
                name="file_path"
                label="File Path"
                type="text"
                id="file_path"
                defaultValue={dataItem?.file_path}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Box sx={{ px: 1.3 }}>
              <Box
                noValidate
                sx={{
                  minHeight: 120,
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  justifyContent: "space-between",
                }}
              >
                <Button
                  margin="normal"
                  component="label"
                  variant="outlined"
                  sx={{
                    height: 40,
                    width: { xs: "100%", sm: "40%", md: "30%" },
                  }}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload file
                  <input
                    type="file"
                    onChange={handleFileChange}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    style={{ display: "none" }}
                  />
                </Button>
                {(imagePreview ||
                  (dataItem?.thumbnail_path && !removeImage)) && (
                  <Button
                    margin="normal"
                    color="error"
                    component="label"
                    variant="outlined"
                    sx={{
                      height: 40,
                      width: { xs: "100%", sm: "40%", md: "30%" },
                      my: { xs: 1.5, sm: 0 },
                      mx: { xs: 0, sm: 1.5, md: 0 },
                    }}
                    startIcon={<DeleteIcon />}
                    onClick={handleRemoveImage}
                  >
                    Remove File
                  </Button>
                )}
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignSelf={"center"}
                >
                  {imagePreview && (
                    <Box
                      component="img"
                      sx={{
                        height: 350,
                        width: 280,
                        objectFit: "contain",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      }}
                      src={imagePreview}
                    ></Box>
                  )}
                  {!imagePreview &&
                    dataItem?.thumbnail_path &&
                    !removeImage && (
                      <Box
                        component="img"
                        sx={{
                          height: 350,
                          width: 280,
                          objectFit: "contain",
                          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        }}
                        src={dataItem?.thumbnail_path}
                      ></Box>
                    )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </GeneralModal>
  );
}
