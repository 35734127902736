import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Index from "./pages/Index";
import { Cities } from "./pages/Cities";
import { Box } from "@mui/material";
import { JobTitle } from "./pages/JobTitle";
import { Employer } from "./pages/Employer";
import { useRecoilState } from "recoil";
import { userInfo } from "./recoil";
import { JobField } from "./pages/JobField";
import { Jobs } from "./pages/Jobs";
import { Seeker } from "./pages/Seeker";
import { Banner } from "./pages/Banner";
import { ProfileSeekerView } from "./pages/ProfileSeekerView";
import { Notifications } from "./pages/Notifications";
import ApplecantStages from "./pages/ApplecantStages";
import CvTemplates from "./pages/CvTemplates";
import { AdminNotes } from "./pages/AdminNotes";
import { Feedback } from "./pages/Feedback";
import { EmployerField } from "./pages/EmployerField";
import { Admins } from "./pages/Admins";
const RouterApp = () => {
  const [userInfoData] = useRecoilState(userInfo);

  return (
    <Box sx={{ mt: 5, ml: 5, mr: 2 }}>
      <Router>
        <Routes>
          {!userInfoData ? (
            <Route path="*" element={<Login />} />
          ) : (
            <>
              <Route path="/" element={<Index />} />
              <Route path="/login" element={<Login />} />
              <Route path="/cities" element={<Cities />} />
              <Route path="/job-title" element={<JobTitle />} />
              <Route path="/job-field" element={<JobField />} />
              <Route path="/employer" element={<Employer />} />
              <Route path="/jobs" element={<Jobs />} />
              <Route path="/applicant-stages" element={<ApplecantStages />} />
              <Route path="/seeker" element={<Seeker />} />
              <Route path="/banner" element={<Banner />} />
              <Route path="/notifications" element={<Notifications />} />
              <Route
                path="/profile-seeker/:id"
                element={<ProfileSeekerView />}
              />
              <Route path="/cv-templates" element={<CvTemplates />} />
              <Route path="/admin-notes" element={<AdminNotes />} />
              <Route path="/feedback" element={<Feedback />} />
              <Route path="/employer-field" element={<EmployerField />} />
              <Route path="/admins" element={<Admins />} />
            </>
          )}
        </Routes>
      </Router>
    </Box>
  );
};

export default RouterApp;
