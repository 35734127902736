import React, { useEffect } from "react";
import { FormControl, TextField, Autocomplete } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { dataClickItem } from "../../recoil/global";
import { GetProvinceData } from "../../services/api/cities";
import {
  dataSelectProvinceState,
  saveProvinceState,
} from "../../recoil/select";

const SelectProvince = (props) => {
  const [dataSelectProvince, setDataSelectProvince] = useRecoilState(
    dataSelectProvinceState
  );
  const dataItem = useRecoilValue(dataClickItem);
  const [data, setData] = useRecoilState(saveProvinceState);

  const handleChange = (event, newValue) => {
    setDataSelectProvince(newValue);
  };

  useEffect(() => {
    // get data cities
    if (data === null) {
      GetProvinceData().then((data) => {
        setData(data.data);
      });
    }
  }, [dataItem]);

  return (
    <FormControl fullWidth margin="normal" sx={{ width: "100%" }}>
      <Autocomplete
        sx={{ width: "100%", minWidth: { xs: 0, sm: 300 } }}
        size="small"
        disablePortal
        id="province-auto-select"
        options={data ? data : ""}
        getOptionLabel={(option) =>
          option?.name_en === undefined ? "" : option?.name_en
        }
        value={dataSelectProvince}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} label="Province" />}
      />
    </FormControl>
  );
};

export default SelectProvince;
