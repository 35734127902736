import { Card, CardContent, LinearProgress, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useSetRecoilState } from "recoil";
import { updateDataState } from "../../recoil/global";
import { useEffect } from "react";
import { NoData } from "../Others/NoData";

export const Grid = ({ data, columns, isLoading, ...props }) => {
  const borders_shadow = {
    borderRadius: "12px",
    boxShadow:
      "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
  };

  return (
    <Card
      sx={{
        margin: 0,
        padding: 0,
        ...borders_shadow,
      }}
      className="card-data-grid"
    >
      <CardContent>
        {!isLoading ? (
          <DataGrid
            sx={{
              minHeight: "80vh",
              width: "100%",
              margin: 0,
              padding: 0,
              "& .employer-grid--header": {
                backgroundColor: "#1a8b9d",
                color: "#fff",
              },
              ...borders_shadow,
            }}
            autoHeight
            virtualization
            rowHeight={55}
            rows={data}
            columns={columns}
            // rowCount={rowCountState}
            loading={isLoading}
            pageSizeOptions={[10]}
            paginationMode="server"
            components={{
              LoadingOverlay: LinearProgress,
              //   NoRowsOverlay: () => (
              //     // <NoRowsOverlay noRowsMessage={noRowsMessage} />
              //   ),
            }}
            {...props}
          />
        ) : (
          <Box
            sx={{
              width: "100%",
              minHeight: "80vh",
            }}
          >
            <LinearProgress />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
