import React, { useState, useEffect } from "react";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { toast } from "react-toastify";
import { openModalInfo } from "../../../recoil/modal";
import { updateDataState } from "../../../recoil/global";
import GeneralModal from "../../GeneralModal/GeneralModal";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import {
  NotifyAll,
  NotifyGuests,
  NotifyUserGroup,
  NotifyUsers,
} from "../../../services/api/notifications";
import SelectUser from "../../Selects/SelectUser";
import { selectedUsersState } from "../../../recoil/select";

export default function ModalInfo() {
  const [open, setOpen] = useRecoilState(openModalInfo);
  const setUpdateData = useSetRecoilState(updateDataState);
  const [group, setGroup] = useState("");
  const user = useRecoilValue(selectedUsersState);
  const setUser = useSetRecoilState(selectedUsersState);
  console.log(user.flatMap(({ id }) => id).join());
  const handleChange = (event) => {
    setGroup(event.target.value);
  };
  // send request
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log(data);
    // function api request
    if (group == "all") {
      const infoData = {
        title_ar: data.get("title_ar"),
        title_en: data.get("title_en"),
        body_ar: data.get("body_ar"),
        body_en: data.get("body_en"),
      };
      NotifyAll(infoData)
        .then((res) => {
          if (res.status < 300) {
            console.log(res);
            toast.success(res?.message);
            handleClose();
            setUpdateData(Math.random());
          }
        })
        .catch((err) => {
          toast.error(<div>{err.response?.data?.errors[0]}</div>);
        });
    } else if (group == "admin" || group == "seeker" || group == "employer") {
      const infoData = {
        title_ar: data.get("title_ar"),
        title_en: data.get("title_en"),
        body_ar: data.get("body_ar"),
        body_en: data.get("body_en"),
        type: group,
      };
      NotifyUserGroup(infoData)
        .then((res) => {
          if (res.status < 300) {
            console.log(res);
            toast.success(res?.message);
            handleClose();
            setUpdateData(Math.random());
          }
        })
        .catch((err) => {
          toast.error(<div>{err.response?.data?.errors[0]}</div>);
        });
    } else if (group == "guest") {
      const infoData = {
        title_ar: data.get("title_ar"),
        title_en: data.get("title_en"),
        body_ar: data.get("body_ar"),
        body_en: data.get("body_en"),
        users: group,
      };
      NotifyGuests(infoData)
        .then((res) => {
          if (res.status < 300) {
            console.log(res);
            toast.success(res?.message);
            handleClose();
            setUpdateData(Math.random());
          }
        })
        .catch((err) => {
          toast.error(<div>{err.response?.data?.errors[0]}</div>);
        });
      // } else if (group == "user") {
      //   const infoData = {
      //     title_ar: data.get("title_ar"),
      //     title_en: data.get("title_en"),
      //     body_ar: data.get("body_ar"),
      //     body_en: data.get("body_en"),
      //     users: user.flatMap(({ id }) => id).join(),
      //   };
      //   NotifyGuests(infoData)
      //     .then((res) => {
      //       if (res.status < 300) {
      //         console.log(res);
      //         toast.success(res?.message);
      //         handleClose();
      //         setUpdateData(Math.random());
      //       }
      //     })
      //     .catch((err) => {
      //       toast.error(<div>{err.response?.data?.errors[0]}</div>);
      //     });
      // }
    }
  };

  // close modal
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    setUser([]);
    setGroup("");
  }, [open]);

  return (
    <GeneralModal maxWidth="md" open={open} onSubmit={handleSubmit}>
      <Box sx={{ mx: 2, my: 2 }}>
        <Box sx={{ mx: 2, mt: 1, display: "flex", alignItems: "center" }}>
          <ContactEmergencyIcon color={"primary"} />
          <Typography variant="h5" fontSize={18} fontWeight={500}>
            &nbsp; Send Notification
          </Typography>
        </Box>

        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                fullWidth
                name="title_en"
                required
                label="Title EN"
                type="text"
                id="title_en"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                fullWidth
                required
                name="title_ar"
                label="Title AR"
                type="text"
                id="title_ar"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                fullWidth
                required
                name="body_en"
                label="Body EN"
                type="text"
                id="body_en"
                multiline
                rows={4}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                fullWidth
                required
                name="body_ar"
                label="Body AR"
                type="text"
                id="body_ar"
                multiline
                rows={4}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                width: "40%",
              }}
            >
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                value={group}
                size="small"
                onChange={handleChange}
              >
                <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={"admin"}>Admins</MenuItem>
                <MenuItem value={"employer"}>Employers</MenuItem>
                <MenuItem value={"seeker"}>Seekers</MenuItem>
                <MenuItem value={"guest"}>Guests</MenuItem>
                {/* <MenuItem value={"user"}>User</MenuItem> */}
              </Select>
            </Box>
            {/* {group == "user" && (
              <Box
                sx={{
                  mx: 2,
                  width: "60%",
                }}
              >
                <SelectUser />
              </Box>
            )} */}
          </Grid>
        </Grid>
      </Box>
    </GeneralModal>
  );
}
