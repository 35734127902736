import { useEffect } from "react";
import { Box, Avatar } from "@mui/material";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { DataGridTabel } from "../../components/DataGridTabel/DataGridTabel";
import { ColumnText } from "../DataGridTabel/ColumnText";
import { ColumnButton } from "../DataGridTabel/ColumnButton";
import { HeaderGridTabel } from "../DataGridTabel/HeaderGridTabel";
import { ModalProfileSeeker } from "../ProfileSeeker/ModalProfileSeeker";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { openModalProfileSeeketState } from "../../recoil/modal";

import {
  dataClickItem,
  methodRequestStatus,
  updateDataState,
} from "../../recoil/global";
import { openModalDelete } from "../../recoil/modal";
import ModalDelete from "./Modal/ModalDelete";
import { useState } from "react";
import { GetSeekerData } from "../../services/api/seeker";
import BadgeIcon from "@mui/icons-material/Badge";
import { dataTabelState } from "../../recoil/Tabel";
import { useRecoilState } from "recoil";

export const Tabel = () => {
  const [data, setData] = useRecoilState(dataTabelState);
  const updateData = useRecoilValue(updateDataState);
  const setDataItem = useSetRecoilState(dataClickItem);
  const setOpenDelete = useSetRecoilState(openModalDelete);
  const setMethodRequest = useSetRecoilState(methodRequestStatus);
  const setOpenModalProfileSeeket = useSetRecoilState(
    openModalProfileSeeketState
  );

  const handelOpenModalProfileSeeker = (data) => {
    setOpenModalProfileSeeket(true);
    setDataItem({ seeker: data });
    console.log("data", data);
  };
  const handelDelete = (data) => {
    setOpenDelete(true);
    setDataItem(data);
    setMethodRequest("delete");
  };

  const columns = [
    {
      field: "name_en",
      headerName: "Name",
      headerClassName: "employer-grid--header",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <Avatar
              sx={{
                border: "2px solid #1a8b9d",
              }}
              sizes="small"
              alt="Remy Sharp"
              src={params.row?.profile_pic}
            />
            <ColumnText
              text={params.row?.name ? params.row?.name : "N/A"}
              variant="subtitle1"
              fontWeight="bold"
            />
            {/* <ColumnText text={params.row?.job_title?.name_ar} name_ar="caption" color={"gray"} /> */}
          </Box>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "employer-grid--header",
      flex: 1,
      minWidth: 220,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={params.row.email ? params.row.email : "N/A"}
              variant="subtitle2"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "mobile",
      headerName: "Mobile",
      flex: 1,
      headerClassName: "employer-grid--header",
      minWidth: 120,
      renderCell: (params) => {
        return (
          <ColumnText
            text={params.row?.mobile ? params.row?.mobile : "N/A"}
            variant="subtitle2"
            fontWeight="bold"
          />
        );
      },
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      minWidth: 250,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        var t = "";
        if (params.row?.city?.province?.name_en && params.row?.city?.name_en) {
          t = `${params.row?.city?.province?.name_en} - ${params.row?.city?.name_en}`;
        } else if (
          params.row?.city?.province?.name_en &&
          !params.row?.city?.name_en
        ) {
          t = params.row?.city?.province?.name_en;
        } else if (
          !params.row?.city?.province?.name_en &&
          params.row?.city?.name_en
        ) {
          t = params.row?.city?.name_en;
        } else {
          t = "N/A";
        }
        return <ColumnText text={t} variant="subtitle2" fontWeight="bold" />;
      },
    },
    {
      field: "marital_status_name_en",
      headerName: "Marital Status",
      flex: 1,
      minWidth: 110,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ColumnText
              text={
                params.row?.marital_status_name_en
                  ? params.row?.marital_status_name_en
                  : "N/A"
              }
              variant="subtitle2"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "gender",
      headerName: "Gender",
      flex: 1,
      minWidth: 90,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ColumnText
              text={params.row?.gender ? params.row?.gender : "N/A"}
              variant="subtitle2"
              fontWeight="bold"
              sx={{ textTransform: "capitalize" }}
            />
          </Box>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created at",
      flex: 1,
      minWidth: 170,
      headerClassName: "employer-grid--header",

      renderCell: (params) => {
        return (
          <ColumnText
            text={new Date(params.row?.created_at).toLocaleString()}
            variant="subtitle2"
            fontWeight="bold"
          />
        );
      },
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      minWidth: 120,
      headerClassName: "employer-grid--header",
      sortable: false,

      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnButton
              onClick={() => handelDelete(params.row)}
              type={"delete"}
            />
            <ColumnButton
              title="User"
              color={"info"}
              onClick={() => handelOpenModalProfileSeeker(params.row)}
              icon={<AccountBoxIcon color="info" />}
            />
          </Box>
        );
      },
    },
  ];

  const fetchData = () => {
    setData((old) => ({
      ...old,
      loading: true,
    }));
    console.log("search", data.filters.search);
    GetSeekerData(data.filters.skip, data.filters.take, data.filters.search)
      .then((res) => {
        console.log("Dtest", res);
        setData((old) => ({
          ...old,
          data: res?.data,
          total: res?.total,
          loading: false,
        }));
      })
      .catch(() => {
        setData({ ...data, loading: false });
      });
  };

  let filters = data.filters.page || data.filters.take || data.filters.skip;

  useEffect(() => {
    fetchData();
  }, [filters, updateData]);

  useEffect(() => {
    return () => {
      setData({
        data: [],
        loading: false,
        dialog: false,
        filters: {
          skip: 0,
          take: 25,
          page: 0,
          search: "",
        },
      });
      setOpenModalProfileSeeket(false);
    };
  }, []);
  return (
    <>
      <Box m={2}>
        <HeaderGridTabel
          title="Seekers"
          icon={<BadgeIcon size={25} color={"primary"} />}
          fetchData={fetchData}
        />
        <Box mt={2}>
          <DataGridTabel columns={columns} data={data} setData={setData} />
        </Box>

        {/* Modal */}
        <ModalDelete />
        <ModalProfileSeeker />
      </Box>
    </>
  );
};

export default Tabel;
