import React from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Box, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { openModalDelete } from "../../../recoil/modal";
import { dataClickItem, updateDataState } from "../../../recoil/global";
import GeneralModal from "../../GeneralModal/GeneralModal";
import { deleteTemplate } from "../../../services/api/cvTemplate";

export default function ModalDelete() {
  const [open, setOpen] = useRecoilState(openModalDelete);
  const dataItem = useRecoilValue(dataClickItem);
  const setUpdateData = useSetRecoilState(updateDataState);

  const handleSubmit = (event) => {
    event.preventDefault();
    deleteTemplate(dataItem?.id)
      .then((res) => {
        console.log(res);
        toast.success(res?.message);
        handleClose();
        setUpdateData(Math.random());
      })
      .catch((err) => {
        toast.error(<div>{err.response?.data?.errors[0]}</div>);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <GeneralModal maxWidth={"sm"} open={open} onSubmit={handleSubmit}>
      <Box sx={{ mx: 2, my: 1 }}>
        <Typography variant="h2" fontSize={18} fontWeight={400}>
          Do you want to delete {dataItem?.name}?
        </Typography>
      </Box>
    </GeneralModal>
  );
}
