import React, { useEffect } from "react";
import { FormControl, TextField, Autocomplete } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { dataClickItem } from "../../recoil/global";
import { GetWorkType } from "../../services/api/workType";
import {
  dataSelectAdminNotesStatusState,
  saveAdminNotesStatusState,
} from "../../recoil/select";

const SelectAdminNotesStatus = (props) => {
  const [dataSelectAdminNotesStatus, setDataSelectAdminNotesStatus] =
    useRecoilState(dataSelectAdminNotesStatusState);
  const dataItem = useRecoilValue(dataClickItem);
  const [data, setData] = useRecoilState(saveAdminNotesStatusState);
  const status = ["pending", "done", "rejected"];

  const handleChange = (event, newValue) => {
    setDataSelectAdminNotesStatus(newValue);
    console.log(newValue);
  };

  useEffect(() => {
    // get status
    if (data === null) {
      setData(status);
    }
    if (dataItem) {
      setDataSelectAdminNotesStatus(dataItem?.status);
    }
  }, [dataItem]);

  return (
    <FormControl fullWidth margin="normal">
      <Autocomplete
        sx={{ minWidth: 300 }}
        size="medium"
        disablePortal
        id="combo-box-demo"
        options={status}
        getOptionLabel={(option) => (option === undefined ? "" : option)}
        value={dataSelectAdminNotesStatus}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} label="Status" />}
      />
    </FormControl>
  );
};

export default SelectAdminNotesStatus;
