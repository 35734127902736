import React from "react";
import Slider from "../components/Slidbar/Slidbar";
import Tabel from "../components/Cities/Tabel";

export const Cities = () => {

  return (
    <>
      <Slider />
      <Tabel />
    </>
  );
};
