import { deleteData, getData, postData, putData } from "..";

// get Job Title
export const GetNotifications = async (skip, take, search) => {
  try {
    let searchItem = search == undefined ? "" : search;
    const request = await getData(
      `/admin/notifications?skip=${skip}&take=${take}&search=${searchItem}`
    ); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
  }
};

// post Job Title
export const NotifyAll = async (data) => {
  try {
    const request = await postData(`/admin/notifications/all`, data); // Handle the data
    return request;
  } catch (error) {
    throw error; // Handle the error
  }
};

export const NotifyUsers = async (data) => {
  try {
    const request = await postData(`/admin/notifications/users`, data); // Handle the data
    return request;
  } catch (error) {
    throw error; // Handle the error
  }
};

export const NotifyUserGroup = async (data) => {
  try {
    const request = await postData(`/admin/notifications/group`, data); // Handle the data
    return request;
  } catch (error) {
    throw error; // Handle the error
  }
};

export const NotifyGuests = async (data) => {
  try {
    const request = await postData(`/admin/notifications/users`, data); // Handle the data
    return request;
  } catch (error) {
    throw error; // Handle the error
  }
};

// delete Job Title
export const DeleteNotifications = async (id) => {
  try {
    const request = await deleteData(`/admin/notifications/${id}`); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};
