import { deleteData, getData, postData, putData } from "..";

export const GetEmployerFieldData = async (skip, take, search) => {
  try {
    const request = await getData(
      `/admin/employer-field?skip=${skip}&take=${take}&search=${search}`
    );
    return request;
  } catch (error) {
    throw error;
  }
};

export const PostEmployerField = async (data) => {
  try {
    const request = await postData(`/admin/employer-field`, data);
    return request;
  } catch (error) {
    throw error;
  }
};

export const PutEmployerField = async (id, data) => {
  try {
    const request = await putData(`/admin/employer-field/${id}`, data);
    return request;
  } catch (error) {
    throw error;
  }
};

export const DeleteEmployerField = async (id) => {
  if (id) {
    try {
      const request = await deleteData(`/admin/employer-field/${id}`);
      return request;
    } catch (error) {
      throw error;
    }
  }
};
