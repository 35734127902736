import React, { useEffect } from "react";
import { FormControl, TextField, Autocomplete } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { dataClickItem } from "../../recoil/global";
import { GetWorkType } from "../../services/api/workType";
import {
  dataSelectWorkTypeState,
  saveWorkTypeState,
} from "../../recoil/select";

const SelectWorkType = (props) => {
  const [dataSelectWorkType, setDataSelectWorkType] = useRecoilState(
    dataSelectWorkTypeState
  );
  const dataItem = useRecoilValue(dataClickItem);
  const [data, setData] = useRecoilState(saveWorkTypeState);

  const handleChange = (event, newValue) => {
    setDataSelectWorkType(newValue);
    console.log(newValue);
  };

  useEffect(() => {
    // get data work type
    if (data === null) {
      GetWorkType().then((data) => {
        setData(data.data);
      });
    }
    if (dataItem) {
      setDataSelectWorkType(dataItem?.work_type);
    }
  }, [dataItem]);

  return (
    <FormControl fullWidth margin="normal">
      <Autocomplete
        sx={{ minWidth: 300 }}
        size="small"
        disablePortal
        id="combo-box-demo"
        options={data ? data : ""}
        getOptionLabel={(option) =>
          option?.name_en === undefined ? "" : option?.name_en
        }
        value={dataSelectWorkType}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField {...params} label="WorkType" required />
        )}
      />
    </FormControl>
  );
};

export default SelectWorkType;
