import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Autocomplete,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { selectedStatusState } from "../../recoil/select";

const SelectStatus = (props) => {
  const [selectedStatus, setSelectedStatus] =
    useRecoilState(selectedStatusState);

  const handleChange = (event, newValue) => {
    setSelectedStatus(newValue);
  };

  const data = ["pending", "rejected", "accepted"];

  return (
    <FormControl fullWidth margin="normal" sx={{ minWidth: 300 }}>
      <Autocomplete
        size="small"
        sx={{ minWidth: 300 }}
        disablePortal
        id="combo-box-demo"
        options={data ? data : ""}
        getOptionLabel={(option) => (option === undefined ? "" : option)}
        onChange={handleChange}
        value={selectedStatus}
        renderInput={(params) => <TextField {...params} label="Status" />}
      />
    </FormControl>
  );
};

export default SelectStatus;
