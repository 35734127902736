import React, { useEffect } from "react";
import { FormControl, Autocomplete, TextField } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { selectedJobFieldState } from "../../recoil/select";
import { useState } from "react";
import { GetJobFieldData } from "../../services/api/jobField";
import { dataClickItem } from "../../recoil/global";

const SelectJobField = (props) => {
  const [selectedJobField, setSelectedJobField] = useRecoilState(
    selectedJobFieldState
  );
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const dataItem = useRecoilValue(dataClickItem);

  const handleSelect = (_, value) => {
    setSelectedJobField(value);
  };

  const handleInputChange = (_, newInputValue) => {
    setSearch(newInputValue);
  };

  useEffect(() => {
    setLoading(true);
    GetJobFieldData(0, 10000, search).then((data) => {
      setData(data.data);
      setLoading(false);
    });
  }, [search]);

  useEffect(() => {
    if (dataItem) {
      setSelectedJobField(dataItem.job_field);
    }
  }, [dataItem]);

  return (
    <FormControl fullWidth margin="normal">
      <Autocomplete
        size="small"
        disablePortal
        options={data || []}
        getOptionLabel={(option) => option?.name_en || ""}
        value={selectedJobField}
        onChange={handleSelect}
        onInputChange={handleInputChange}
        loading={loading}
        getOptionSelected={(option, value) => option.id === value.id} // Compare based on unique identifier (e.g., ID)
        renderInput={(params) => (
          <TextField
            {...params}
            label="Job Field"
            InputLabelProps={{
              shrink: props.shr,
            }}
            required={props.req}
          />
        )}
        ListboxProps={{
          style: { maxHeight: "100px" },
        }}
      />
    </FormControl>
  );
};

export default SelectJobField;
