import { deleteData, getData, postData, putData } from "..";

// get Admin Notes
export const AdminNotesData = async (skip, take, search, status) => {
  try {
    let searchItem = status ? "" : search;
    const request = await getData(
      `/admin/admin-notes?skip=${skip}&take=${take}&search=${
        search == null ? "" : search
      }&status=${status == null ? "" : status}`
    ); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
  }
};
// put Admin Notes
export const PutAdminNotesStatus = async (id, data) => {
  try {
    const request = await putData(`/admin/admin-notes/${id}`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
  }
};
