import { deleteData, getData, postData, putData } from "..";

// get Employer
export const GetEmployerData = async (
  skip,
  take,
  search,
  email,
  mobile,
  status,
  province_id
) => {
  if ((skip !== undefined && take !== undefined) || search !== search) {
    try {
      let searchType = email || mobile || status || province_id ? "" : search;
      const request = await getData(
        `/admin/employer?skip=${skip}&take=${take}&search=${searchType}&email=${
          email ? email : ""
        }&mobile=${mobile ? mobile : ""}&status=${
          status ? status : ""
        }&province_id=${province_id ? province_id : ""}`
      ); // Handle the data
      return request;
    } catch (error) {
      console.error(error); // Handle the error
      throw error;
    }
  }
};

// get Employer By Id
export const GetEmployerByIdData = async (id) => {
  if (id) {
    try {
      const response = await getData(`/admin/employer/${id}`); // Handle the data
      return response;
    } catch (error) {
      console.error(error); // Handle the error
      throw error;
    }
  }
};

// post Employer
export const PostEmployer = async (data) => {
  try {
    const request = await postData(`/admin/employer`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// put Employer
export const PutEmployer = async (id, data) => {
  try {
    const request = await putData(`/admin/employer/${id}`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

export const PostEmployerLogo = async (id, data) => {
  try {
    const request = await postData(`/admin/employer/profile-pic/${id}`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

export const DeleteEmployerLogo = async (id) => {
  try {
    const request = await deleteData(`/admin/employer/profile-pic/${id}`);
    return request;
  } catch (error) {
    throw error;
  }
};
// delete Employer
export const DeleteEmployer = async (id) => {
  try {
    const request = await deleteData(`/admin/employer/${id}`); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// change passwoed employer
export const PostChangePasswordEmployer = async (id, data) => {
  try {
    const request = await postData(`/admin/user/change_password/${id}`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// change accepted or rejected employer
export const PostChangeStatusEmployer = async (data) => {
  try {
    const request = await postData(`/admin/employer/reject-accept`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};
