import React, { useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useRecoilState } from "recoil";
import { selectedCurencyState } from "../../recoil/select";
import { dataClickItem } from "../../recoil/global";

const SelectCurrency = () => {
  const [selectedCurency, setSelectedCurency] =
    useRecoilState(selectedCurencyState);
  const dataItem = useRecoilState(dataClickItem);
  const data = ["USD", "IQD"];
  const handleChange = (event) => {
    setSelectedCurency(event.target.value);
  };
  useEffect(() => {
    if (dataItem[0]) {
      setSelectedCurency(dataItem[0]?.currency);
    }
  }, [dataItem[0]]);
  console.log("cuurency dataitem", dataItem[0]);

  return (
    <FormControl fullWidth margin="normal" sx={{ minWidth: 300 }}>
      <InputLabel id="curency-label" sx={{ background: "#fff" }}>
        Currency
      </InputLabel>
      <Select
        size="small"
        sx={{ minWidth: 300 }}
        labelId="status-label"
        id="status-select"
        onChange={handleChange}
        value={selectedCurency}
      >
        {data?.map((curency, index) => (
          <MenuItem key={index} value={curency}>
            {curency}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectCurrency;
