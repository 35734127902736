import React, { useEffect, useState } from 'react';
import { FormControl, TextField, Autocomplete } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { JobTitleData } from '../../services/api/jobTitle';
import { dataClickItem } from '../../recoil/global';
import { dataSelectJobTitleState } from '../../recoil/select';

const SelectJobTitle = () => {
    const [dataSelectJobTitle, setDataSelectJobTitle] = useRecoilState(dataSelectJobTitleState);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const dataItem = useRecoilValue(dataClickItem);

    const handleChange = (event, newValue) => {
        // Save the selected value to the dataSelectJobTitle state
        setDataSelectJobTitle(newValue);
    };

    const handleInputChange = (event, newInputValue) => {
        // Store the input text in the state
        setSearch(newInputValue);
    };

    useEffect(() => {
        // Fetch data when the search term changes
        setLoading(true);
        JobTitleData(0, 10, search).then(data => {
            setData(data.data);
            setLoading(false);
        });
    }, [search]);

    useEffect(() => {
        if (dataItem) {
            setDataSelectJobTitle(dataItem.job_title);
        }
    }, [dataItem]);


    return (
        <FormControl fullWidth margin="normal">
            <Autocomplete
                sx={{ minWidth: 300 }}
                size='small'
                disablePortal
                id="combo-box-demo"
                options={data || []}
                getOptionLabel={(option) => option?.name_en || ''}
                value={dataSelectJobTitle}
                onChange={handleChange} // Update the dataSelectJobTitle state when an item is selected
                onInputChange={handleInputChange}
                loading={loading}
                renderInput={(params) => <TextField {...params} label="Job Title" />}
            />
        </FormControl>
    );
};

export default SelectJobTitle;
