import Slider from "../components/Slidbar/Slidbar";
import Tabel from "../components/Jobs/Tabel";

export const Jobs = () => {

  return (
    <>
      <Slider />
      <Tabel />
    </>
  );
};

export default Jobs;
