import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import {
  openModalApplicant,
  openModalDelete,
  openModalInfo,
  openModalChangePassword,
} from "../../recoil/modal";
import { methodRequestStatus } from "../../recoil/global";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DrawIcon from "@mui/icons-material/Draw";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import LockIcon from "@mui/icons-material/Lock";
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function GeneralModal(props) {
  const setOpenInfo = useSetRecoilState(openModalInfo);
  const setOpenDelete = useSetRecoilState(openModalDelete);
  const setOpenApplicant = useSetRecoilState(openModalApplicant);
  const setOpenChangePassword = useSetRecoilState(openModalChangePassword);
  const methodRequest = useRecoilValue(methodRequestStatus);

  const handleClose = () => {
    setOpenInfo(false);
    setOpenDelete(false);
    setOpenApplicant(false);
    setOpenChangePassword(false);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        maxWidth={props.maxWidth ? props.maxWidth : "lg"}
        aria-labelledby="draggable-dialog-title"
        sx={{
          width: "100%",
          overflow: "hidden",
          borderRadius: 10,
        }}
      >
        <DialogTitle
          color={"primary"}
          id="draggable-dialog-title"
          style={{
            cursor: "move",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            borderBottom: "1px solid #bdc3c7",
          }}
        >
          {methodRequest === "remove-footer" && (
            <Tooltip title="Close">
              <IconButton color="error" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          )}
          {methodRequest === "add" ? (
            <AddCircleIcon color={"primary"} />
          ) : methodRequest === "edit" ? (
            <DrawIcon color={"primary"} />
          ) : methodRequest === "delete" ? (
            <DeleteIcon color={"primary"} />
          ) : props.changePassword ? (
            <LockIcon color={"primary"} />
          ) : (
            props.icon
          )}
          <Typography variant="h6" fontWeight={600} color={"primary"}>
            &nbsp;
            {methodRequest === "add"
              ? "Add"
              : methodRequest === "edit"
              ? "Edit"
              : methodRequest === "delete"
              ? "Delete"
              : methodRequest === "activate"
              ? "Admin Activation"
              : props.changePassword
              ? "Change password"
              : props.title}
          </Typography>
        </DialogTitle>
        <Box component="form" onSubmit={props.onSubmit}>
          <DialogContent sx={{ overflow: "hidden" }}>
            <DialogContentText>{props.children}</DialogContentText>
          </DialogContent>
          {methodRequest !== "remove-footer" && (
            <DialogActions>
              <Button
                onClick={handleClose}
                sx={{ textTransform: "capitalize" }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{ textTransform: "capitalize" }}
              >
                {methodRequest === "add"
                  ? "Add"
                  : methodRequest === "edit"
                  ? "Edit"
                  : methodRequest === "activate"
                  ? "Yes"
                  : props.changePassword
                  ? "Save"
                  : "Delete"}
              </Button>
            </DialogActions>
          )}
        </Box>
      </Dialog>
    </div>
  );
}
