import { useEffect, useState } from "react";
import { Box, Avatar, Typography } from "@mui/material";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { DataGridTabel } from "../../components/DataGridTabel/DataGridTabel";
import { dataTabelState } from "../../recoil/Tabel";
import { ColumnText } from "../DataGridTabel/ColumnText";
import { ColumnButton } from "../DataGridTabel/ColumnButton";
import { GetJobsData } from "../../services/api/jobs";
import { HeaderGridTabel } from "../DataGridTabel/HeaderGridTabel";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ModalInfo from "./Modal/ModalInfo";
import {
  dataClickItem,
  methodRequestStatus,
  updateDataState,
} from "../../recoil/global";
import {
  openModalApplicant,
  openModalDelete,
  openModalInfo,
} from "../../recoil/modal";
import ModalDelete from "./Modal/ModalDelete";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import { ModalApplicant } from "./Modal/ModalApplicant";
import { ModalProfileSeeker } from "../ProfileSeeker/ModalProfileSeeker";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Filter from "./Filters";
import {
  dataSelectCompanyState,
  dataSelectJobTitleState,
  dataSelectProvinceState,
  selectedExpiredState,
  selectedJobFieldState,
} from "../../recoil/select";

export const Tabel = () => {
  const [data, setData] = useRecoilState(dataTabelState);

  const updateData = useRecoilValue(updateDataState);
  const dataSelectProvince = useRecoilValue(dataSelectProvinceState);
  const selectedExpired = useRecoilValue(selectedExpiredState);
  const selectedJobField = useRecoilValue(selectedJobFieldState);
  const dataSelectJobTitle = useRecoilValue(dataSelectJobTitleState);
  const dataSelectCompany = useRecoilValue(dataSelectCompanyState);

  const setDataItem = useSetRecoilState(dataClickItem);
  const setOpenModal = useSetRecoilState(openModalInfo);
  const setOpenDelete = useSetRecoilState(openModalDelete);
  const setMethodRequest = useSetRecoilState(methodRequestStatus);
  const [openApplicant, setOpenApplicant] = useRecoilState(openModalApplicant);

  const colorDate = (myData) => {
    const currentDate = new Date(); // Current date
    const providedDate = new Date(myData);

    // Compare dates
    if (currentDate > providedDate) {
      return "red";
    } else {
      return "green";
    }
  };

  const handelEdit = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("edit");
  };

  const handelDelete = (data) => {
    setOpenDelete(true);
    setDataItem(data);
    setMethodRequest("delete");
  };

  const handelApplicant = (data) => {
    setOpenApplicant(true);
    setDataItem(data);
    setMethodRequest("remove-footer");
  };
  const columns = [
    {
      field: "name_en",
      headerName: "Job Title",
      headerClassName: "employer-grid--header",
      flex: 1,
      minWidth: 250,
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={params.row?.job_title?.name_en}
              variant="subtitle1"
              fontWeight="bold"
            />
            <ColumnText
              text={params.row?.job_title?.name_ar}
              name_ar="caption"
              color={"gray"}
            />
          </Box>
        );
      },
    },
    {
      field: "company_name",
      headerName: "Company Name",
      headerClassName: "employer-grid--header",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <Avatar src={params.row?.company?.profile_pic} sizes={"small"} />
            <ColumnText
              text={params.row?.company?.company_name}
              variant="subtitle2"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "eduaction_degree",
      headerName: "Eduaction Degree",
      flex: 1,
      minWidth: 100,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <ColumnText
            text={
              params.row?.education_degree?.name_en &&
              params.row?.education_degree?.name_en
            }
            variant="subtitle2"
            fontWeight="bold"
          />
        );
      },
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      minWidth: 200,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <ColumnText
            text={`${params.row?.city?.province?.name_en} - ${params.row?.city?.name_en}`}
            variant="subtitle2"
            fontWeight="bold"
          />
        );
      },
    },
    {
      field: "views_count",
      headerName: "Views & Appliers",
      flex: 1,
      minWidth: 120,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <Box
            key={params.row?.id}
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <ColumnText
              text={params.row?.views_count}
              variant="subtitle2"
              fontWeight="bold"
            />
            &nbsp;
            <VisibilityIcon color="warning" />
            &nbsp;
            <Typography variant="subtitle2">|</Typography>
            &nbsp;
            <ColumnText
              text={params.row?.appliers}
              variant="subtitle2"
              fontWeight="bold"
            />
            &nbsp;
            <DoneAllIcon color="success" />
          </Box>
        );
      },
    },
    {
      field: "salary",
      headerName: "Salary",
      flex: 1,
      minWidth: 150,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={`${params.row?.salary_from} - ${params.row?.salary_to}`}
              variant="subtitle2"
              fontWeight="bold"
            />
            <ColumnText
              text={params.row?.currency}
              name_ar="currency"
              variant="body"
              color={"gray"}
            />
          </Box>
        );
      },
    },
    {
      field: "expired_date",
      headerName: "Expired Date",
      flex: 1,
      minWidth: 100,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <ColumnText
            color={colorDate(params.row?.due_to)}
            text={params.row?.due_to?.split(" ")[0]}
            variant="subtitle2"
            fontWeight="bold"
          />
        );
      },
    },
    {
      field: "operations",
      headerName: "Operations",
      sortable: false,
      flex: 1,
      minWidth: 170,
      headerClassName: "employer-grid--header",

      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnButton
              onClick={() => handelEdit(params.row)}
              type={"edit"}
            />

            <ColumnButton
              onClick={() => handelDelete(params.row)}
              type={"delete"}
            />
            <ColumnButton
              title="Applicant"
              color={"info"}
              onClick={() => handelApplicant(params.row)}
              icon={<RecentActorsIcon color="info" />}
            />
          </Box>
        );
      },
    },
  ];

  const fetchData = () => {
    setData((old) => ({
      ...old,
      loading: true,
    }));

    let selectProvince = dataSelectProvince?.id ? dataSelectProvince?.id : "";
    let jobFieldId = selectedJobField?.id ? selectedJobField?.id : "";
    let jobTitleId = dataSelectJobTitle?.id ? dataSelectJobTitle?.id : "";
    let companyId = dataSelectCompany?.company_id
      ? dataSelectCompany?.company_id
      : "";

    GetJobsData(
      data.filters.skip,
      data.filters.take,
      data.filters.search,
      selectedExpired,
      selectProvince,
      jobFieldId,
      jobTitleId,
      companyId
    )
      .then((res) => {
        console.log("Dtest", res);
        setData((old) => ({
          ...old,
          data: res?.data,
          total: res?.total,
          loading: false,
        }));
      })
      .catch(() => {
        setData({ ...data, loading: false });
      });
  };

  let filters = data.filters.page || data.filters.take || data.filters.skip;

  useEffect(() => {
    fetchData();
  }, [filters, updateData]);

  useEffect(() => {
    return () => {
      setData({
        data: [],
        loading: false,
        dialog: false,
        filters: {
          skip: 0,
          take: 25,
          page: 0,
          search: "",
        },
      });
      setOpenApplicant(false);
    };
  }, []);
  useEffect(() => {
    if (!openApplicant) {
      setDataItem(null);
    }
  }, [openApplicant]);
  return (
    <>
      <Box m={2}>
        <HeaderGridTabel
          title="Jobs"
          icon={<HomeWorkIcon size={25} color={"primary"} />}
          btn={"Add Job"}
          fetchData={fetchData}
          isFilter={true}
        />
        <Box mt={2}>
          <DataGridTabel columns={columns} data={data} setData={setData} />
        </Box>
        {/* Modal */}
        <ModalInfo />
        <ModalDelete />
        <ModalApplicant />
        <ModalProfileSeeker />
        <Filter fetchData={fetchData} />
      </Box>
    </>
  );
};

export default Tabel;
