import { getData } from "..";

// get Admin Notes
export const FeedbackData = async (skip, take, search) => {
  try {
    let searchItem = search == undefined ? "" : search;
    const request = await getData(
      `/admin/feedback?skip=${skip}&take=${take}&search=${searchItem}`
    ); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
  }
};
