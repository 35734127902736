import { useEffect } from "react";
import { Box, Switch } from "@mui/material";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { DataGridTabel } from "../DataGridTabel/DataGridTabel";
import { ColumnText } from "../DataGridTabel/ColumnText";
import { ColumnButton } from "../DataGridTabel/ColumnButton";
import { HeaderGridTabel } from "../DataGridTabel/HeaderGridTabel";
import { dataTabelState } from "../../recoil/Tabel";
import { openModalInfo, openModalDelete } from "../../recoil/modal";
import {
  dataClickItem,
  methodRequestStatus,
  updateDataState,
} from "../../recoil/global";
import ModalInfo from "./Modal/ModalInfo";
import { GetAdminData } from "../../services/api/Admins";
import { userInfo } from "../../recoil";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import ModalDeactivate from "./Modal/ModalDeactivate";
export const Table = () => {
  const [data, setData] = useRecoilState(dataTabelState);
  const updateData = useRecoilValue(updateDataState);
  const setDataItem = useSetRecoilState(dataClickItem);
  const setOpenModal = useSetRecoilState(openModalInfo);
  const setOpenDelete = useSetRecoilState(openModalDelete);
  const setMethodRequest = useSetRecoilState(methodRequestStatus);
  const setUpdateData = useSetRecoilState(updateDataState);
  const info = useRecoilValue(userInfo);

  const handleDelete = (data) => {
    setOpenDelete(true);
    setDataItem(data);
    setMethodRequest("activate");
  };
  const handleEdit = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("edit");
  };
  const columns = [
    {
      field: "name",
      headerName: "Name",
      headerClassName: "employer-grid--header",
      sortable: false,
      flex: 1,
      minWidth: 220,
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={params?.row?.name}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "employer-grid--header",
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={params?.row?.email}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "mobile",
      headerName: "Mobile",
      flex: 1,
      headerClassName: "employer-grid--header",
      minWidth: 120,
      renderCell: (params) => {
        return (
          <ColumnText
            text={params.row?.mobile ? params.row?.mobile : "N/A"}
            variant="subtitle2"
            fontWeight="bold"
          />
        );
      },
    },
    {
      field: "is_active",
      headerName: "Status",
      flex: 1,
      minWidth: 70,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <Switch
            disabled={info?.id == params.row?.id}
            checked={params.row?.is_active}
            color="success"
            defaultChecked={params.row?.is_active}
            onChange={() => handleDelete(params.row)}
          ></Switch>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created at",
      flex: 1,
      minWidth: 170,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={
                params.row?.created_at
                  ? new Date(params.row?.created_at).toLocaleString()
                  : "N/A"
              }
              variant="subtitle2"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      minWidth: 80,
      sortable: false,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnButton
              onClick={() => handleEdit(params.row)}
              type={"edit"}
            />
          </Box>
        );
      },
    },
  ];

  const fetchData = () => {
    setData((old) => ({
      ...old,
      loading: true,
    }));

    GetAdminData(data.filters.skip, data.filters.take, data.filters.search)
      .then((res) => {
        console.log("Dtest", res);
        setData((old) => ({
          ...old,
          data: res?.data,
          total: res?.total,
          loading: false,
        }));
      })
      .catch(() => {
        setData({ ...data, loading: false });
      });
  };

  let filters = data.filters.page || data.filters.take || data.filters.skip;

  useEffect(() => {
    fetchData();
  }, [filters, updateData]);

  useEffect(() => {
    return () => {
      setData({
        data: [],
        loading: false,
        dialog: false,
        filters: {
          skip: 0,
          take: 25,
          page: 0,
          search: "",
        },
      });
    };
  }, []);
  return (
    <>
      <Box m={2}>
        <HeaderGridTabel
          title="Admins"
          icon={<SupervisorAccountIcon size={25} color={"primary"} />}
          fetchData={fetchData}
          btn={"Add Admin"}
        />
        <Box mt={2}>
          <DataGridTabel columns={columns} data={data} setDat={setData} />
        </Box>
        {/* Modal */}
        <ModalInfo />
        <ModalDeactivate />
      </Box>
    </>
  );
};

export default Table;
