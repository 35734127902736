import { useEffect } from "react";
import { Box } from "@mui/material";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { DataGridTabel } from "../../components/DataGridTabel/DataGridTabel";
import { ColumnText } from "../DataGridTabel/ColumnText";
import { ColumnButton } from "../DataGridTabel/ColumnButton";
import { HeaderGridTabel } from "../DataGridTabel/HeaderGridTabel";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ModalInfo from "./Modal/ModalInfo";
import {
  dataClickItem,
  methodRequestStatus,
  updateDataState,
} from "../../recoil/global";
import { openModalDelete, openModalInfo } from "../../recoil/modal";
import ModalDelete from "./Modal/ModalDelete";
import { GetJobFieldData } from "../../services/api/jobField";
import { dataTabelState } from "../../recoil/Tabel";

export const Tabel = () => {
  const [data, setData] = useRecoilState(dataTabelState);
  const updateData = useRecoilValue(updateDataState);
  const setDataItem = useSetRecoilState(dataClickItem);
  const setOpenModal = useSetRecoilState(openModalInfo);
  const setOpenDelete = useSetRecoilState(openModalDelete);
  const setMethodRequest = useSetRecoilState(methodRequestStatus);

  const handelEdit = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("edit");
  };

  const handelDelete = (data) => {
    setOpenDelete(true);
    setDataItem(data);
    setMethodRequest("delete");
  };
  const columns = [
    {
      field: "name_en",
      headerName: "Name EN",
      headerClassName: "employer-grid--header",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={params.row?.name_en}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "name_ar",
      headerName: "Name AR",
      headerClassName: "employer-grid--header",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={params.row?.name_ar}
              variant="subtitle2"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      minWidth: 100,
      sortable: false,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnButton
              onClick={() => handelEdit(params.row)}
              type={"edit"}
            />
            <ColumnButton
              onClick={() => handelDelete(params.row)}
              type={"delete"}
            />
          </Box>
        );
      },
    },
  ];

  const fetchData = () => {
    setData((old) => ({
      ...old,
      loading: true,
    }));

    GetJobFieldData(data.filters.skip, data.filters.take, data.filters.search)
      .then((res) => {
        console.log("Dtest", res);
        setData((old) => ({
          ...old,
          data: res?.data,
          total: res?.total,
          loading: false,
        }));
      })
      .catch(() => {
        setData({ ...data, loading: false });
      });
  };

  let filters = data.filters.page || data.filters.take || data.filters.skip;

  useEffect(() => {
    fetchData();
  }, [filters, updateData]);

  useEffect(() => {
    return () => {
      setData({
        data: [],
        loading: false,
        dialog: false,
        filters: {
          skip: 0,
          take: 25,
          page: 0,
          search: "",
        },
      });
    };
  }, []);
  return (
    <>
      <Box m={2}>
        <HeaderGridTabel
          title="Job Field"
          icon={<HomeWorkIcon size={25} color={"primary"} />}
          btn={"Add Job Field"}
          fetchData={fetchData}
        />
        <Box mt={2}>
          <DataGridTabel columns={columns} data={data} setData={setData} />
        </Box>
        {/* Modal */}
        <ModalInfo />
        <ModalDelete />
      </Box>
    </>
  );
};

export default Tabel;
