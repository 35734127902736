import * as React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useRecoilState, useRecoilValue } from "recoil";
import { openModalProfileSeeketState } from "../../recoil/modal";
import { ProfileSeeker } from "./ProfileSeeker";
import { Box } from "@mui/material";
import { dataClickItem } from "../../recoil/global";
import { GetSeekerByIdData } from "../../services/api/seeker";
import { useState } from "react";

export const ModalProfileSeeker = (props) => {
  const [data, setData] = useState(null);
  const [open, setOpen] = useRecoilState(openModalProfileSeeketState);
  const dataItem = useRecoilValue(dataClickItem);

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    console.log("profile seeker", dataItem);
    GetSeekerByIdData(dataItem?.seeker?.id)
      .then((res) => {
        console.log("profile seeker", res);
        setData(res.data[0]);
      })
      .catch((err) => {
        console.error("get profile seeker err", err);
      });
  }, [dataItem]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={"lg"}
      sx={{
        width: "100%",
      }}
    >
      <AppBar sx={{ width: "100%", position: "relative" }} color={"primary"}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Profile Seeker
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ width: "100%" }}>
        <ProfileSeeker data={data} />
      </Box>
    </Dialog>
  );
};
