import { postData } from "..";

export const PostChangePassword = async (data) => {
  try {
    const request = await postData(
      "https://api-test.wadhifety.com/api/auth/change-password?locale=en",
      data
    ); // Handle the data
    return request;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
