import React, { useEffect } from "react";
import { FormControl, Autocomplete, TextField } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { selectedEmployerFieldState } from "../../recoil/select";
import { useState } from "react";
import { GetEmployerFieldData } from "../../services/api/EmployerField/EmployerField";
import { dataClickItem } from "../../recoil/global";

const SelectEmployerField = (props) => {
  const [selectedEmployerField, setSelectedEmployerField] = useRecoilState(
    selectedEmployerFieldState
  );
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const dataItem = useRecoilValue(dataClickItem);

  const handleSelect = (_, value) => {
    setSelectedEmployerField(value);
  };

  const handleInputChange = (_, newInputValue) => {
    setSearch(newInputValue);
  };

  useEffect(() => {
    setLoading(true);
    GetEmployerFieldData(0, 10, search).then((data) => {
      setData(data.data);
      setLoading(false);
    });
  }, [search]);

  useEffect(() => {
    if (dataItem) {
      setSelectedEmployerField(dataItem?.employer_field);
    }
  }, [dataItem]);
  console.log("selectedEmployerField", selectedEmployerField);
  return (
    <FormControl fullWidth margin="normal">
      <Autocomplete
        size="small"
        disablePortal
        options={data || []}
        getOptionLabel={(option) => option?.name_en || ""}
        value={selectedEmployerField}
        onChange={handleSelect}
        onInputChange={handleInputChange}
        loading={loading}
        getOptionSelected={(option, value) => option.id === value.id} // Compare based on unique identifier (e.g., ID)
        renderInput={(params) => (
          <TextField required {...params} label="Employer Field" />
        )}
      />
    </FormControl>
  );
};

export default SelectEmployerField;
