import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useSetRecoilState } from "recoil";
import { userInfo } from "./recoil";
import LoadingCircle from "./components/Others/LoadingCircle";
import { Layout } from "./layout";
import { Box } from "@mui/material";

function App() {
  axios.defaults.baseURL = "https://api.wadhifety.com/api";
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + Cookies.get("token");
  const setUserInfoData = useSetRecoilState(userInfo);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`/user/info`)
      .then((res) => {
        console.log(res.data);
        setUserInfoData(res.data?.data[0]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <div className="App">
      {loading ? (
        <Box sx={{ mt: "47vh" }}>
          <LoadingCircle />
        </Box>
      ) : (
        <>
          <Layout />
          <ToastContainer />
        </>
      )}
    </div>
  );
}

export default App;
