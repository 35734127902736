import { useEffect, useMemo } from "react";
import { Box } from "@mui/material";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { DataGridTabel } from "../DataGridTabel/DataGridTabel";
import { ColumnText } from "../DataGridTabel/ColumnText";
import { ColumnButton } from "../DataGridTabel/ColumnButton";
import { HeaderGridTabel } from "../DataGridTabel/HeaderGridTabel";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ModalInfo from "./Modal/ModalInfo";
import {
  dataClickItem,
  methodRequestStatus,
  updateDataState,
} from "../../recoil/global";
import { openModalDelete } from "../../recoil/modal";
import ModalDelete from "./Modal/ModalDelete";
import { dataTabelState } from "../../recoil/Tabel";
import { GetNotifications } from "../../services/api/notifications";

export const Tabel = () => {
  const [data, setData] = useRecoilState(dataTabelState);
  const updateData = useRecoilValue(updateDataState);
  const setDataItem = useSetRecoilState(dataClickItem);
  const setOpenDelete = useSetRecoilState(openModalDelete);
  const setMethodRequest = useSetRecoilState(methodRequestStatus);

  const handelDelete = (data) => {
    setOpenDelete(true);
    setDataItem(data);
    setMethodRequest("delete");
  };
  const columns = useMemo(
    () => [
      {
        field: "title_en",
        headerName: "Title EN",
        headerClassName: "employer-grid--header",
        flex: 1,
        minWidth: 150,
        renderCell: (params) => {
          return (
            <Box>
              <ColumnText
                text={params.row?.title_en}
                variant="subtitle1"
                fontWeight="bold"
              />
            </Box>
          );
        },
      },
      {
        field: "title_ar",
        headerName: "Title AR",
        headerClassName: "employer-grid--header",
        flex: 1,
        minWidth: 150,
        renderCell: (params) => {
          return (
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <ColumnText
                text={params.row?.title_ar}
                variant="subtitle2"
                fontWeight="bold"
              />
            </Box>
          );
        },
      },
      {
        field: "body_en",
        headerName: "Body EN",
        headerClassName: "employer-grid--header",
        flex: 1,
        minWidth: 345,
        renderCell: (params) => {
          return (
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <ColumnText
                text={params.row?.body_en}
                variant="subtitle2"
                fontWeight="bold"
              />
            </Box>
          );
        },
      },
      {
        field: "body_ar",
        headerName: "Body AR",
        headerClassName: "employer-grid--header",
        flex: 1,
        minWidth: 345,
        renderCell: (params) => {
          return (
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <ColumnText
                text={params.row?.body_ar}
                variant="subtitle2"
                fontWeight="bold"
              />
            </Box>
          );
        },
      },
      {
        field: "createdAt",
        headerName: "Created At",
        headerClassName: "employer-grid--header",
        flex: 1,
        minWidth: 170,
        renderCell: (params) => {
          return (
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <ColumnText
                text={
                  params.row?.created_at
                    ? new Date(params.row?.created_at).toLocaleString()
                    : "N/A"
                }
                variant="subtitle2"
                fontWeight="bold"
              />
            </Box>
          );
        },
      },
      {
        field: "operations",
        headerName: "Operations",
        flex: 1,
        minWidth: 100,
        headerClassName: "employer-grid--header",
        sortable: false,
        renderCell: (params) => {
          return (
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <ColumnButton
                onClick={() => handelDelete(params.row)}
                type={"delete"}
              />
            </Box>
          );
        },
      },
    ],
    []
  );

  const fetchData = () => {
    setData((old) => ({
      ...old,
      loading: true,
    }));

    GetNotifications(data.filters.skip, data.filters.take, data.filters.search)
      .then((res) => {
        console.log("Dtest", res);
        setData((old) => ({
          ...old,
          data: res?.data,
          total: res?.total,
          loading: false,
        }));
      })
      .catch(() => {
        setData({ ...data, loading: false });
      });
  };

  let filters = data.filters.page || data.filters.take || data.filters.skip;

  useEffect(() => {
    fetchData();
  }, [filters, updateData]);

  useEffect(() => {
    return () => {
      setData({
        data: [],
        loading: false,
        dialog: false,
        filters: {
          skip: 0,
          take: 25,
          page: 0,
          search: "",
        },
      });
    };
  }, []);
  return (
    <>
      <Box m={2}>
        <HeaderGridTabel
          title="Notifications"
          icon={<HomeWorkIcon size={25} color={"primary"} />}
          btn={"Send Notification"}
          fetchData={fetchData}
        />
        <Box mt={2}>
          <DataGridTabel columns={columns} data={data} setData={setData} />
        </Box>
        {/* Modal */}
        <ModalInfo />
        <ModalDelete />
      </Box>
    </>
  );
};

export default Tabel;
