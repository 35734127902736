import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { openModalInfo } from "../../../recoil/modal";
import SelectProvince from "../../Selects/SelectProvince";
import SelectCity from "../../Selects/SelectCity";
import SelectCompany from "../../Selects/SelectCompany";
import SelectJobTitle from "../../Selects/SelectJobTitle";
import SelectWorkType from "../../Selects/SelectWorkType";
import SelectGender from "../../Selects/SelectGender";
import { PostJobs, PutJobs, GetJobsByIdData } from "../../../services/api/jobs";
import SelectEducationsDegree from "../../Selects/SelectEducationsDegree";
import {
  dataClickItem,
  methodRequestStatus,
  updateDataState,
} from "../../../recoil/global";
import {
  dataSelectCityState,
  dataSelectCompanyState,
  dataSelectEducationsDegreeState,
  dataSelectGenderState,
  dataSelectJobTitleState,
  dataSelectProvinceState,
  dataSelectWorkTypeState,
  selectedCityState,
  selectedCurencyState,
} from "../../../recoil/select";
import GeneralModal from "../../GeneralModal/GeneralModal";
import BusinessIcon from "@mui/icons-material/Business";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import SelectCurrency from "../../Selects/SelectCurrency";
import dayjs from "dayjs";

export default function ModalInfo() {
  const [open, setOpen] = useRecoilState(openModalInfo);
  const [dataSelectCity, setDataSelectCity] =
    useRecoilState(dataSelectCityState);
  const [dataSelectCompany, setDataSelectCompany] = useRecoilState(
    dataSelectCompanyState
  );
  const [dataSelectJobTitle, setDataSelectJobTitle] = useRecoilState(
    dataSelectJobTitleState
  );
  const [dataSelectWorkType, setDataSelectWorkType] = useRecoilState(
    dataSelectWorkTypeState
  );
  const [dataSelectEducationsDegree, setDataSelectEducationsDegree] =
    useRecoilState(dataSelectEducationsDegreeState);
  const [dataSelectGender, setDataSelectGender] = useRecoilState(
    dataSelectGenderState
  );
  const [dataSelect, setDataSelect] = useRecoilState(dataSelectProvinceState);
  const setUpdateData = useSetRecoilState(updateDataState);

  const [dataItem, setDataItem] = useRecoilState(dataClickItem);
  const methodRequest = useRecoilValue(methodRequestStatus);
  const selectedCurency = useRecoilValue(selectedCurencyState);

  // convert to int
  const convertToInt = (string) => {
    var newString = string?.replace(",", "");
    var newInt = parseInt(newString);
    return newInt;
  };
  // send request
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const infoData = {
      job_title_id: dataSelectJobTitle?.id,
      other_job_title: data.get("other_job_title"),
      work_days: data.get("work_days"),
      work_type_id: dataSelectWorkType?.id,
      description: data.get("description"),
      due_to: data.get("due_to"),
      city_id: dataSelectCity.id,
      full_address: data.get("full_address"),
      education_degree_id: dataSelectEducationsDegree?.id,
      gender: dataSelectGender,
      salary_from: data.get("salary_from"),
      salary_to: data.get("salary_to"),
      currency: selectedCurency,
      company_id: dataSelectCompany?.company_id,
      is_active: true,
    };

    // function api request
    if (methodRequest === "add") {
      PostJobs(infoData)
        .then((res) => {
          if (res.status < 300) {
            console.log(res);
            toast.success(res?.message);
            handleClose();
            setUpdateData(Math.random());
          }
        })
        .catch((err) => {
          toast.error(<div>{err.response?.data?.errors[0]}</div>);
        });
    } else {
      const idItem = dataItem?.id;
      PutJobs(idItem, infoData)
        .then((res) => {
          console.log(res);
          toast.success(res?.message);
          handleClose();
          setUpdateData(Math.random());
        })
        .catch((err) => {
          toast.error(<div>{err.response?.data?.errors[0]}</div>);
        });
    }
  };

  // close modal
  const handleClose = () => {
    setOpen(false);
  };

  // remove old data
  useEffect(() => {
    console.log("effect");
    setDataSelectEducationsDegree(null);
    setDataSelectGender(null);
    setDataSelectWorkType(null);
    setDataSelectJobTitle(null);
    setDataSelectCity(null);
    setDataSelect(null);
    setDataSelectCompany(null);
    setDataItem(null);
  }, [open]);

  useEffect(() => {
    if (dataItem?.id) {
      GetJobsByIdData(dataItem?.id).then((res) => {
        setDataItem(res?.data[0]);
      });
    }
  }, [dataItem?.id]);
  return (
    <GeneralModal maxWidth="md" open={open} onSubmit={handleSubmit}>
      <Box sx={{ mx: 2, my: 2 }}>
        <Typography
          sx={{ mx: 2, mt: 1, display: "flex", alignItems: "center" }}
          variant="h5"
          fontSize={18}
          fontWeight={500}
        >
          <BusinessIcon color={"primary"} />
          &nbsp; Company Information
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box noValidate sx={{ width: "100%" }}>
                <Box noValidate sx={{ width: "100%" }}>
                  <SelectCompany req={true} />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "100%" }}></Box>
            </Box>
          </Grid>
        </Grid>
        <Typography
          sx={{ mx: 2, mt: 1 }}
          variant="h5"
          fontSize={18}
          fontWeight={500}
        >
          <ContactEmergencyIcon color={"primary"} />
          &nbsp; Job Information
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box noValidate sx={{ width: "100%" }}>
                <SelectProvince req={true} />
              </Box>
              <Box noValidate sx={{ width: "100%" }}>
                <SelectEducationsDegree />
              </Box>
              <TextField
                key={dataItem?.work_days}
                size="small"
                name="work_days"
                fullWidth
                label="Work Days"
                defaultValue={dataItem?.work_days}
                id="work_days"
                type="text"
                margin="normal"
              ></TextField>
              <TextField
                key={dataItem?.due_to}
                sx={{ minWidth: 300 }}
                size="small"
                margin="normal"
                fullWidth
                required
                name="due_to"
                label="Due To"
                type="date"
                id="due_to"
                defaultValue={
                  methodRequest === "add"
                    ? dayjs().add(6, "month").format("YYYY-MM-DD")
                    : dataItem?.due_to
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Box noValidate sx={{ width: "100%" }}>
                <SelectGender />
              </Box>
              <TextField
                key={dataItem?.salary_from}
                sx={{ minWidth: 300 }}
                size="small"
                margin="normal"
                fullWidth
                name="salary_from"
                label="Salary From"
                type="number"
                id="salary_from"
                defaultValue={convertToInt(dataItem?.salary_from)}
              />
              <TextField
                key={dataItem?.description}
                multiline
                minRows={3}
                rowsMin={2}
                size="small"
                margin="normal"
                fullWidth
                name="description"
                label="Description"
                type="text"
                id="description"
                defaultValue={dataItem?.description}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box noValidate sx={{ width: "100%" }}>
                <SelectCity />
              </Box>
              <Box noValidate sx={{ width: "100%" }}>
                <SelectWorkType />
              </Box>
              <Box noValidate sx={{ width: "100%" }}>
                <SelectJobTitle />
              </Box>
              <TextField
                key={dataItem?.other_job_title}
                size="small"
                fullWidth
                margin="normal"
                name="other_job_title"
                label="Other Job Title"
                disabled={dataSelectJobTitle?.id !== 1}
                required={dataSelectJobTitle?.id === 1}
                type="text"
                defaultValue={dataItem?.other_job_title}
                id="other_job_title"
              />
              <TextField
                key={dataItem?.full_address}
                size="small"
                margin="normal"
                fullWidth
                name="full_address"
                label="Address"
                type="text"
                id="full_address"
                defaultValue={dataItem?.full_address}
              />
              <TextField
                key={dataItem?.salary_to}
                sx={{ minWidth: 300 }}
                size="small"
                margin="normal"
                fullWidth
                name="salary_to"
                label="Salary To"
                type="number"
                id="salary_to"
                defaultValue={convertToInt(dataItem?.salary_to)}
              />
              <Box noValidate sx={{ width: "100%" }}>
                <SelectCurrency />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </GeneralModal>
  );
}
