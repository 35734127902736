import React, { useCallback, useEffect, useMemo } from "react";
import { Box } from "@mui/material";
import { ColumnText } from "../DataGridTabel/ColumnText";
import { ColumnButton } from "../DataGridTabel/ColumnButton";
import { Grid } from "../DataGridTabel/Grid";
import { getApplecantStages } from "../../services/api/job-applecant";
import {
  dataClickItem,
  methodRequestStatus,
  updateDataState,
} from "../../recoil/global";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { openModalDelete, openModalInfo } from "../../recoil/modal";
import ModalDelete from "./Modal/ModalDelete";
import { dataTabelState } from "../../recoil/Tabel";
import ModalInfo from "./Modal/ModalInfo";
import { Header } from "../DataGridTabel/Header";

const Table = () => {
  const [data, setData] = useRecoilState(dataTabelState);
  const setOpenDelete = useSetRecoilState(openModalDelete);
  const setMethodRequest = useSetRecoilState(methodRequestStatus);
  const setDataItem = useSetRecoilState(dataClickItem);
  const updateData = useRecoilValue(updateDataState);
  const setOpenModal = useSetRecoilState(openModalInfo);

  const columns = useMemo(
    () => [
      {
        field: "name_en",
        headerName: "Name EN",
        headerClassName: "employer-grid--header",
        flex: 1,
        sortable: false,
        minWidth: 150,
        renderCell: (params) => {
          return (
            <Box>
              <ColumnText
                text={params.row?.name_en}
                variant="subtitle1"
                fontWeight="bold"
              />
            </Box>
          );
        },
      },
      {
        field: "name_ar",
        headerName: "Name AR",
        headerClassName: "employer-grid--header",
        sortable: false,
        flex: 1,
        minWidth: 100,
        renderCell: (params) => {
          return (
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <ColumnText
                text={params.row?.name_ar}
                variant="subtitle2"
                fontWeight="bold"
              />
            </Box>
          );
        },
      },
      {
        field: "operations",
        headerName: "Operations",
        flex: 1,
        headerClassName: "employer-grid--header",
        sortable: false,
        minWidth: 100,
        renderCell: (params) => {
          return (
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <ColumnButton
                onClick={() => handelEdit(params.row)}
                type={"edit"}
              />
              <ColumnButton
                onClick={() => handelDelete(params.row)}
                type={"delete"}
              />
            </Box>
          );
        },
      },
    ],
    []
  );

  const handelEdit = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("edit");
  };
  const handelDelete = (data) => {
    setOpenDelete(true);
    setDataItem(data);
    setMethodRequest("delete");
  };

  const fetchData = useCallback(() => {
    setData((old) => ({
      ...old,
      loading: true,
    }));

    getApplecantStages()
      .then((res) => {
        setData((old) => ({
          ...old,
          data: res?.data,
          total: res?.total,
          loading: false,
        }));
      })
      .catch(() => {
        setData({ ...data, loading: false });
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [updateData]);

  useEffect(() => {
    return () => {
      setData({
        data: [],
        loading: false,
        dialog: false,
        filters: {
          skip: 0,
          take: 25,
          page: 0,
          search: "",
        },
      });
    };
  }, []);

  return (
    <Box sx={{}}>
      <Header
        title="Applicant Stages"
        btnTitle={"Add Stage"}
        isSearchable={false}
        fetchData={fetchData}
      />
      <Box>
        <Grid
          columns={columns}
          data={data.data}
          totalRows={data.total}
          isLoading={data.loading}
        />
      </Box>
      <ModalInfo />
      <ModalDelete />
    </Box>
  );
};

export default Table;
