import { deleteData, getData, postData, putData } from "..";

export const getCvTemplates = async () => {
  try {
    const response = await getData(`/admin/cv-templates`);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const deleteTemplate = async (id) => {
  try {
    const response = await deleteData(`/admin/cv-templates/${id}`);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const postCvTemplate = async (data) => {
  try {
    const request = await postData(`/admin/cv-templates`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error);
  }
};

export const putCvTemplate = async (id, data) => {
  try {
    const request = await postData(`/admin/cv-templates/${id}`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error);
  }
};
