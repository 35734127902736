import { deleteData, getData, postData, putData } from "..";

// get Seeker
export const GetSeekerData = async (skip, take, search) => {
  try {
    let searchItem = search == undefined ? "" : search;
    const request = await getData(
      `/admin/seeker?skip=${skip}&take=${take}&search=${searchItem}`
    ); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// get Seeker by id
export const GetSeekerByIdData = async (id) => {
  if (id) {
    try {
      const request = await getData(`/admin/seeker/${id}`); // Handle the data
      return request;
    } catch (error) {
      console.error(error); // Handle the error
      throw error;
    }
  }
};

// delete Seeker
export const DeleteSeeker = async (id) => {
  try {
    const request = await deleteData(`/admin/seeker/${id}`); // Handle the data
    return request;
  } catch (error) {
    throw error;
  }
};
