import { getData } from "..";

// get All Statistics
export const GetAllStatistics = async () => {
  try {
    const request = await getData(`/admin/statistics`); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// get Duration Statistics
export const GetDurationStatistics = async (date) => {
  try {
    const request = await getData(
      `/admin/statistics/duration-statistics?duration=${date}`
    ); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

export const GetProvinceStatistics = async () => {
  try {
    const request = await getData("/admin/statistics/province-statistics");
    return request;
  } catch (error) {
    throw error;
  }
};
