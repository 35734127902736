import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { openModalInfo } from "../../../recoil/modal";
import {
  dataClickItem,
  methodRequestStatus,
  updateDataState,
} from "../../../recoil/global";
import { dataSelectProvinceState } from "../../../recoil/select";
import GeneralModal from "../../GeneralModal/GeneralModal";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import { PostCities, PutCities } from "../../../services/api/cities";
import SelectProvinceCities from "../../Selects/SelectProvinceCities";
export default function ModalInfo() {
  const [open, setOpen] = useRecoilState(openModalInfo);
  const dataItem = useRecoilValue(dataClickItem);
  const setUpdateData = useSetRecoilState(updateDataState);
  const methodRequest = useRecoilValue(methodRequestStatus);
  const [dataSelectProvince, setDataSelectProvince] = useRecoilState(
    dataSelectProvinceState
  );
  console.log(dataSelectProvince);
  console.log("dataitem", dataItem);
  // remove old data
  // useEffect(() => {
  //   setDataSelectProvince(null);
  // }, [open]);

  useEffect(() => {
    console.log("dataitem useEffect", dataItem);
    setDataSelectProvince(dataItem?.province);
    console.log("useEffect dataSelectProvince", dataSelectProvince);
  }, [dataItem]);

  // send request
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const infoData = {
      province_id: dataSelectProvince?.id,
      name_ar: data.get("name_ar"),
      name_en: data.get("name_en"),
    };

    // function api request
    if (methodRequest === "add") {
      PostCities(infoData)
        .then((res) => {
          console.log("res", res);
          if (res.status < 300) {
            toast.success(res?.message);
            handleClose();
            setUpdateData(Math.random());
          }
        })
        .catch((err) => {
          toast.error(<div>{err.response?.data?.errors[0]}</div>);
        });
    } else {
      const idItem = dataItem?.id;
      PutCities(idItem, infoData)
        .then((res) => {
          if (res.status < 300) {
            console.log(res);
            toast.success(res?.message);
            handleClose();
            setUpdateData(Math.random());
          }
        })
        .catch((err) => {
          console.log("err", err);
          toast.error(<div>{err.response?.data?.errors[0]}</div>);
        });
    }
  };

  // close modal
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <GeneralModal maxWidth="md" open={open} onSubmit={handleSubmit}>
      <Box sx={{ mx: 2, my: 2 }}>
        <Typography
          sx={{ mx: 2, mt: 1 }}
          variant="h5"
          fontSize={18}
          fontWeight={500}
        >
          <ContactEmergencyIcon color={"primary"} />
          &nbsp; City Name
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: { xs: 0, sm: 1.5 },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                name="name_en"
                label="Name EN"
                type="text"
                id="name_en"
                sx={{ minWidth: 200 }}
                defaultValue={dataItem?.name_en}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: { xs: 0, sm: 3 },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                name="name_ar"
                label="Name AR"
                type="text"
                id="name_ar"
                sx={{ minWidth: 200 }}
                defaultValue={dataItem?.name_ar}
              />
            </Box>
          </Grid>
        </Grid>
        <Typography
          sx={{ mx: 2, mt: 1 }}
          variant="h5"
          fontSize={18}
          fontWeight={500}
        >
          <ContactEmergencyIcon color={"primary"} />
          &nbsp; More Information
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: { xs: 0, sm: 2 },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box noValidate sx={{ width: "100%", minHeight: 120 }}>
                <SelectProvinceCities />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </GeneralModal>
  );
}
