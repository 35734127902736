import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { openModalInfo } from "../../../recoil/modal";
import SelectProvince from "../../Selects/SelectProvince";
import SelectCity from "../../Selects/SelectCity";
import SelectEmployerField from "../../Selects/SelectEmployerField";
import {
  dataClickItem,
  methodRequestStatus,
  updateDataState,
} from "../../../recoil/global";
import {
  dataSelectCityState,
  dataSelectProvinceState,
  selectedJobFieldState,
  selectedEmployerFieldState,
} from "../../../recoil/select";
import GeneralModal from "../../GeneralModal/GeneralModal";
import {
  GetEmployerByIdData,
  PostEmployer,
  PostEmployerLogo,
  PutEmployer,
  DeleteEmployerLogo,
} from "../../../services/api/employer";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import 'leaflet/dist/leaflet.css';
import { posisionMapState } from "../../../recoil/location";
import DeleteIcon from "@mui/icons-material/Delete";

export default function ModalInfo() {
  const [open, setOpen] = useRecoilState(openModalInfo);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [sendData, setSendData] = useState(false);
  const setDataItem = useSetRecoilState(dataClickItem);
  const dataItem = useRecoilValue(dataClickItem);
  const methodRequest = useRecoilValue(methodRequestStatus);
  const [dataSelectCity, setDataSelectCity] =
    useRecoilState(dataSelectCityState);
  const [dataSelectEmployerField, setSelectedEmployerField] = useRecoilState(
    selectedEmployerFieldState
  );
  const setUpdateData = useSetRecoilState(updateDataState);
  const [dataSelect, setDataSelect] = useRecoilState(dataSelectProvinceState);
  const setSelectedJobFieldInJobTitle = useSetRecoilState(
    selectedJobFieldState
  );
  const posision = useRecoilValue(posisionMapState);
  const [imagePreview, setImagePreview] = useState(null);
  const [removeImage, setRemoveImage] = useState(0);
  // set file data
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };
  const handleRemoveFile = (event) => {
    event.preventDefault();
    setSelectedFile(null);
    if (imagePreview && (dataItem?.profile_pic || !dataItem?.profile_pic)) {
      setImagePreview(null);
    } else if (dataItem?.profile_pic) {
      setRemoveImage(1);
    }
  };
  const handleURL = (url) => {
    if (url) {
      let completeURL = url;
      // Check if the URL contains a protocol, if not, add 'https://' as a default
      if (!url?.includes("http://") && !url?.includes("https://")) {
        completeURL = `https://${url}`;
        return completeURL;
      }
    }
    return url;
  };

  // send request
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const mobileNumber = data.get("mobile");
    if (mobileNumber && mobileNumber.replace(/\D/g, "").length < 11) {
      toast.error("Mobile number must contain 11 digits");
      return;
    }

    // function api request
    if (methodRequest === "add") {
      // data form
      const formData = new FormData();

      formData.append("email", data.get("email"));
      formData.append("password", data.get("password"));
      formData.append("mobile", data.get("mobile"));
      formData.append("name", data.get("name"));
      formData.append("company_name", data.get("company_name"));
      formData.append("employer_field_id", dataSelectEmployerField?.id);
      formData.append("website", handleURL(data.get("website")));
      formData.append("contact_email", data.get("contact_email"));
      formData.append("full_address", data.get("full_address"));
      formData.append("city_id", dataSelectCity?.id);
      formData.append("longitude", posision[0]);
      formData.append("latitude", posision[1]);
      formData.append("facebook", handleURL(data.get("facebook")));
      formData.append("linkedin", handleURL(data.get("linkedin")));
      selectedFile && formData.append("file", selectedFile);

      // function api request
      PostEmployer(formData)
        .then((res) => {
          if (res.status < 300) {
            console.log(res);
            toast.success(res?.message);
            handleClose();
            setUpdateData(Math.random());
            setDataSelect(null);
          }
        })
        .catch((err) => {
          toast.error(<div>{err.response?.data?.errors[0]}</div>);
        });
    } else {
      const data = new FormData(event.currentTarget);
      // data form
      const formData = new FormData();

      const infoData = {
        email: data.get("email"),
        mobile: data.get("mobile"),
        name: data.get("name"),
        company_name: data.get("company_name"),
        employer_field_id: dataSelectEmployerField?.id,
        website: handleURL(data.get("website")),
        contact_email: data.get("contact_email"),
        full_address: data.get("full_address"),
        city_id: dataSelectCity?.id,
        longitude: posision[0],
        latitude: posision[1],
        facebook: handleURL(data.get("facebook")),
        linkedin: handleURL(data.get("linkedin")),
      };
      const idItem = dataItem?.id;
      PutEmployer(idItem, infoData)
        .then((res) => {
          console.log(res);
          toast.success(res?.message);
          handleClose();
          setUpdateData(Math.random());
          setSendData(true);
          setDataSelect(null);
        })
        .catch((err) => {
          toast.error(<div>{err.response?.data?.errors[0]}</div>);
        });
      formData.append("file", selectedFile);

      if (selectedFile) {
        PostEmployerLogo(idItem, formData)
          .then((res) => {
            console.log(res);
            handleClose();
            setUpdateData(Math.random());
            setDataSelect(null);
            setRemoveImage(0);
          })
          .catch((err) => {
            toast.error(<div>{err.response?.data?.errors[0]}</div>);
          });
      }
      if (removeImage) {
        DeleteEmployerLogo(dataItem?.id)
          .then((res) => {
            setRemoveImage(1);
            console.log(res);
          })
          .catch((err) => {
            toast.error(<div>{err.response?.data?.errors[0]}</div>);
          });
      }
    }
  };

  // close modal
  const handleClose = () => {
    setOpen(false);
  };

  // hide and show password
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  // remove old data
  useEffect(() => {
    setSelectedJobFieldInJobTitle(null);
    setDataSelect(null);
    setSelectedEmployerField(null);
    setDataSelectCity(null);
    setImagePreview(null);
    setSelectedFile(null);
    setRemoveImage(0);
  }, [open]);
  useEffect(() => {
    if (dataItem?.id) {
      GetEmployerByIdData(dataItem?.id).then((res) => {
        setDataItem(res?.data[0]);
      });
    }
  }, [dataItem?.id]);
  console.log("profile pic", dataItem?.profile_pic);
  console.log("selectedFile", selectedFile);
  console.log("imagePreview", imagePreview);
  return (
    <GeneralModal maxWidth="md" open={open} onSubmit={handleSubmit}>
      <Box sx={{ mx: 2, my: 2 }}>
        <Typography sx={{ mx: 2 }} variant="h5" fontSize={18} fontWeight={500}>
          Employer Information
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box noValidate>
                <TextField
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  type="email"
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  defaultValue={dataItem?.email}
                />
                <TextField
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  name="mobile"
                  placeholder="07*********"
                  label="Mobile"
                  type="text"
                  id="mobile"
                  defaultValue={dataItem?.mobile}
                  onInput={(e) => {
                    let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digits
                    // if the first digit is not equal to 0, stop taking input
                    if (inputValue.charAt(0) !== "0") {
                      e.target.value = "";
                    }
                    // if the second digit is not equal to 7, stop taking input
                    else if (
                      inputValue.charAt(1) !== "7" &&
                      inputValue.length >= 2
                    ) {
                      e.target.value = inputValue.slice(0, 2);
                    } else {
                      inputValue = inputValue.slice(0, 11); // Limit to 11 characters
                      e.target.value = inputValue;
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={
                    !dataItem?.mobile ? "Phone number must start with 07" : ""
                  }
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                name="name"
                label="Name"
                type="text"
                id="name"
                size="small"
                defaultValue={dataItem?.name}
              />
              {!dataItem && (
                <TextField
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  id="password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
        <Typography
          sx={{ mx: 2, mt: 1 }}
          variant="h5"
          fontSize={18}
          fontWeight={500}
        >
          Company Information
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box noValidate>
                <TextField
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  name="company_name"
                  label="Company"
                  type="text"
                  id="company_name"
                  defaultValue={dataItem?.company_name}
                />
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="website"
                  label="Website"
                  type="text"
                  id="website"
                  defaultValue={dataItem?.website}
                />
                <Box noValidate sx={{ width: "100%" }}>
                  <SelectProvince data={dataSelect} req={true} />
                </Box>
                <Box noValidate sx={{ width: "100%" }}>
                  <SelectCity data={dataSelect} />
                </Box>
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="full_address"
                  label="Address"
                  type="text"
                  id="full_address"
                  defaultValue={dataItem?.full_address}
                />
                <Button
                  margin="normal"
                  component="label"
                  variant="outlined"
                  sx={{ width: "100%", mt: 2 }}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload file
                  <input
                    type="file"
                    onChange={handleFileChange}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    style={{ display: "none" }}
                  />
                </Button>

                {(imagePreview || (dataItem?.profile_pic && !removeImage)) && (
                  <Button
                    margin="normal"
                    color="error"
                    component="label"
                    variant="outlined"
                    startIcon={<DeleteIcon />}
                    sx={{ width: "100%", mt: 2 }}
                    onClick={handleRemoveFile}
                  >
                    Remove File
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box noValidate sx={{ width: "100%" }}>
                <SelectEmployerField />
              </Box>
              <TextField
                size="small"
                margin="normal"
                fullWidth
                name="contact_email"
                label="Contact Email"
                type="email"
                id="contact_email"
                defaultValue={dataItem?.contact_email}
                InputProps={{
                  inputProps: {
                    pattern: "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
                    title: "Please enter a valid email address",
                  },
                }}
              />
              <TextField
                size="small"
                margin="normal"
                fullWidth
                name="facebook"
                label="Facebook"
                type="text"
                id="facebook"
                defaultValue={dataItem?.facebook}
              />
              <TextField
                size="small"
                margin="normal"
                fullWidth
                name="linkedin"
                label="Linkedin"
                type="text"
                id="linkedin"
                defaultValue={dataItem?.linkedin}
              />
              {imagePreview && (
                <Box
                  component={"img"}
                  src={imagePreview}
                  sx={{ width: "50%", mt: 2 }}
                ></Box>
              )}
              {!imagePreview && dataItem?.profile_pic && !removeImage && (
                <Box
                  component={"img"}
                  src={dataItem?.profile_pic}
                  sx={{ width: "50%", mt: 2 }}
                ></Box>
              )}
              {/* <Box sx={{ m: 2 }}>
                <label className="title-location">Select Your Location</label>
                <LocationMap />
              </Box> */}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </GeneralModal>
  );
}
