import React, { useEffect, useState } from "react";
import { FormControl, TextField, Autocomplete } from "@mui/material";
import { useRecoilState } from "recoil";
import { dataClickItem } from "../../recoil/global";
import { dataSelectGenderState } from "../../recoil/select";

const SelectGender = (props) => {
  const [dataSelectGender, setDataSelectGender] = useRecoilState(
    dataSelectGenderState
  );
  const dataItem = useRecoilState(dataClickItem);
  const data = ["Male", "Female", "Not-specified"];

  const handleChange = (event, newValue) => {
    setDataSelectGender(newValue);
    console.log(newValue);
  };

  useEffect(() => {
    if (dataItem[0]) {
      setDataSelectGender(dataItem[0]?.gender);
    }
  }, [dataItem[0]]);
  return (
    <FormControl fullWidth margin="normal">
      <Autocomplete
        sx={{ minWidth: 300 }}
        size="small"
        disablePortal
        id="combo-box-demo"
        options={data ? data : ""}
        getOptionLabel={(option) => (option === undefined ? "" : option)}
        value={dataSelectGender}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} label="Gender" />}
      />
    </FormControl>
  );
};

export default SelectGender;
