import { getData } from "..";

// get Job Title 
export const GetWorkType = async () => {
    try {
        const request = await getData(`/admin/work-type`); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};
