import { useEffect } from "react";
import { Box } from "@mui/material";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { DataGridTabel } from "../DataGridTabel/DataGridTabel";
import { ColumnText } from "../DataGridTabel/ColumnText";
import { HeaderGridTabel } from "../DataGridTabel/HeaderGridTabel";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import { dataTabelState } from "../../recoil/Tabel";
import { dataClickItem, updateDataState } from "../../recoil/global";

import { openFilterState } from "../../recoil/global";
import { FeedbackData } from "../../services/api/Feedback";
export const Tabel = () => {
  const [data, setData] = useRecoilState(dataTabelState);
  const updateData = useRecoilValue(updateDataState);
  const state = useRecoilValue(openFilterState);
  const setDataItem = useSetRecoilState(dataClickItem);
  useEffect(() => {
    setDataItem(null);
  }, [state]);
  const columns = [
    {
      field: "name",
      headerName: "Name",
      headerClassName: "employer-grid--header",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={params?.row?.name}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "employer-grid--header",
      sortable: false,
      flex: 1,
      minWidth: 280,
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={params?.row?.email}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "rating",
      headerName: "Rating",
      flex: 1,
      minWidth: 60,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={`${params?.row?.rating} / 5`}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "message",
      headerName: "Message",
      flex: 1,
      minWidth: 400,
      headerClassName: "employer-grid--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={params?.row?.message}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
  ];

  const fetchData = () => {
    setData((old) => ({
      ...old,
      loading: true,
    }));

    FeedbackData(data.filters.skip, data.filters.take, data.filters.search)
      .then((res) => {
        console.log("Dtest", res);
        setData((old) => ({
          ...old,
          data: res?.data,
          total: res?.total,
          loading: false,
        }));
      })
      .catch(() => {
        setData({ ...data, loading: false });
      });
  };

  let filters = data.filters.page || data.filters.take || data.filters.skip;

  useEffect(() => {
    fetchData();
  }, [filters, updateData]);

  useEffect(() => {
    return () => {
      setData({
        data: [],
        loading: false,
        dialog: false,
        filters: {
          skip: 0,
          take: 25,
          page: 0,
          search: "",
        },
      });
    };
  }, []);
  return (
    <>
      <Box m={2}>
        <HeaderGridTabel
          title="Feedback"
          icon={<HomeWorkIcon size={25} color={"primary"} />}
          fetchData={fetchData}
        />
        <Box mt={2}>
          <DataGridTabel columns={columns} data={data} setDat={setData} />
        </Box>
      </Box>
    </>
  );
};

export default Tabel;
