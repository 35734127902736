import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { useRecoilState } from "recoil";
import { openFilterState } from "../../recoil/global";
import { Typography } from "@mui/material";
import SelectStatus from "../Selects/SelectStatus";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { dataSelectAdminNotesStatusState } from "../../recoil/select";
import SelectAdminNotesStatus from "../Selects/SelectAdminNotesStatus";
export default function Filter(props) {
  const [state, setState] = useRecoilState(openFilterState);
  const [selectedStatus, setSelectedStatus] = useRecoilState(
    dataSelectAdminNotesStatusState
  );
  const handleSearch = () => {
    if (dataSelectAdminNotesStatusState) {
      props.fetchData();
    }
  };

  const handleRemoveSearch = () => {
    setSelectedStatus(null);
  };

  React.useEffect(() => {
    if (selectedStatus == null) {
      props.fetchData();
    }
  }, [selectedStatus]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 350,
        mt: 8,
      }}
      role="presentation"
    >
      <Box sx={{ m: 1, position: "relative", height: "86vh" }}>
        <Typography mt={2} variant="h6">
          Filter Advancis
        </Typography>
        <SelectAdminNotesStatus />
        <Box sx={{ position: "absolute", bottom: 0, right: 0, left: 0 }}>
          <Button
            onClick={() => handleSearch()}
            sx={{ width: "100%" }}
            variant="outlined"
            startIcon={<SearchIcon />}
          >
            Search
          </Button>
          <Button
            onClick={() => handleRemoveSearch()}
            color="error"
            sx={{ width: "100%", mt: 1 }}
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Remove
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"right"} className="mt-5 pt-5">
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
