import { deleteData, getData, postData, putData } from "..";

// get cities 
export const GetCitiesData = async (skip, take, search) => {
    try {
        const data = await getData(`/admin/cities?skip=${skip}&take=${take}&search=${search}`); // Handle the data
        return data;
    } catch (error) {
        console.error(error); // Handle the error
        throw error; // Rethrow the error so it can be caught in the calling code
    }
};


// get province 
export const GetProvinceData = async () => {
    try {
        const data = await getData(`/admin/province?skip&take`); // Handle the data
        return data;
    } catch (error) {
        console.error(error); // Handle the error
        throw error; // Rethrow the error so it can be caught in the calling code
    }
};


// get province 
export const GetProvinceByIdData = async (id) => {
    if (id) {
        try {
            const data = await getData(`/admin/province/${id}`); // Handle the data
            return data;
        } catch (error) {
            console.error(error); // Handle the error
            throw error; // Rethrow the error so it can be caught in the calling code
        }
    }
};

// post Cities
export const PostCities = async (data) => {
    try {
        const request = await postData(`/admin/cities`, data); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Log the error
        throw error; // Rethrow the error so it can be caught in the calling code
    }
};
// post Cities
export const PutCities = async (id, data) => {
    try {
        const request = await putData(`/admin/cities/${id}`, data); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error; // Rethrow the error so it can be caught in the calling code
    }
};

// delete Cities
export const DeleteCities = async (id) => {
    try {
        const request = await deleteData(`/admin/cities/${id}`); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error; // Rethrow the error so it can be caught in the calling code
    }
};
