import { atom } from "recoil";


// value posision map 
export const posisionMapState = atom({
  key: 'posisionMapState',
  default: [0, 0],
});

// value posision map live
export const posisionMapLiveState = atom({
    key: 'posisionMapLiveState',
    default: [0, 0],
  });
  