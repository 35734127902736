import React, { useEffect } from "react";
import { FormControl, TextField, Autocomplete } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { dataClickItem } from "../../recoil/global";
import { GetEducationsDegree } from "../../services/api/educationsDegree";
import {
  dataSelectEducationsDegreeState,
  saveEducationsDegreeState,
} from "../../recoil/select";

const SelectEducationsDegree = (props) => {
  const [dataSelectEducationsDegree, setDataSelectEducationsDegree] =
    useRecoilState(dataSelectEducationsDegreeState);
  const dataItem = useRecoilValue(dataClickItem);
  const [data, setData] = useRecoilState(saveEducationsDegreeState);

  const handleChange = (event, newValue) => {
    setDataSelectEducationsDegree(newValue);
    console.log(newValue);
  };

  useEffect(() => {
    // get data cities
    if (data === null) {
      GetEducationsDegree().then((data) => {
        setData(data.data);
      });
    }
  }, [dataItem]);

  useEffect(() => {
    if (dataItem) {
      setDataSelectEducationsDegree(dataItem.education_degree);
    }
  }, [dataItem]);

  return (
    <FormControl fullWidth margin="normal">
      <Autocomplete
        sx={{ minWidth: 300 }}
        size="small"
        disablePortal
        id="combo-box-demo"
        options={data ? data : ""}
        getOptionLabel={(option) => option?.name_en}
        value={dataSelectEducationsDegree}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField {...params} label="Educations Degree" required />
        )}
      />
    </FormControl>
  );
};

export default SelectEducationsDegree;
