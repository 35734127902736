import React, { useEffect, useState } from "react";
import { FormControl, TextField, Autocomplete } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { dataClickItem } from "../../recoil/global";
import { GetProvinceByIdData } from "../../services/api/cities";
import {
  dataSelectCityState,
  dataSelectProvinceState,
} from "../../recoil/select";

const SelectCity = () => {
  const [dataSelectCity, setDataSelectCity] =
    useRecoilState(dataSelectCityState);

  const dataSelectProvince = useRecoilValue(dataSelectProvinceState);
  const dataItem = useRecoilValue(dataClickItem);
  const [data, setData] = useState([
    {
      id: 0,
      name_en: "",
      name_ar: "",
    },
  ]);

  const handleChange = (event, newValue) => {
    setDataSelectCity(newValue);
    console.log(newValue);
  };

  useEffect(() => {
    // get data cities
    if (dataSelectProvince?.id) {
      GetProvinceByIdData(dataSelectProvince?.id).then((data) => {
        setData(data?.data[0]?.city);
      });
      setDataSelectCity({
        id: dataItem?.city?.id,
        name_en: dataItem?.city?.name_ar,
        name_ar: dataItem?.city?.name_en,
      });
    }
  }, [dataItem, dataSelectProvince]);

  return (
    <FormControl fullWidth margin="normal">
      <Autocomplete
        sx={{ minWidth: 300 }}
        size="small"
        disablePortal
        disabled={!dataSelectProvince?.id}
        id="combo-box-city"
        options={data}
        getOptionLabel={(option) =>
          option?.name_en === undefined ? "" : option?.name_en
        }
        value={{ id: dataSelectCity?.id, name_en: dataSelectCity?.name_en }}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField {...params} label="City" required />
        )}
      />
    </FormControl>
  );
};

export default SelectCity;
