import { getData } from "..";

// get educations Degree 
export const GetEducationsDegree = async () => {
    try {
        const request = await getData(`/admin/educations-degree`); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};
