import { Card, CardContent, LinearProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useSetRecoilState } from "recoil";
import { updateDataState } from "../../recoil/global";
import { useEffect } from "react";
import { NoData } from "../Others/NoData";

export const DataGridTabel = (props) => {
  const setUpdateData = useSetRecoilState(updateDataState);

  const borders_shadow = {
    borderRadius: "12px",
    boxShadow:
      "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
  };

  return (
    <Card
      sx={{
        margin: 0,
        padding: 0,
        ...borders_shadow,
      }}
      className="card-data-grid"
    >
      <CardContent>
        <DataGrid
          sx={{
            height: "80vh",
            width: "100%",
            margin: 0,
            padding: 0,
            "& .employer-grid--header": {
              backgroundColor: "#1a8b9d",
              color: "#fff",
            },
            ...borders_shadow,
          }}
          pageSize={props.data.filters.take}
          rowCount={props.data.total}
          rows={props.data.data}
          loading={props.data.loading}
          page={props.data.filters.page}
          columns={props.columns}
          rowHeight={65}
          pagination
          paginationMode="server"
          components={{
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: NoData,
          }}
          onPageSizeChange={(newPageSize) => {
            props.setData((old) => ({
              ...old,
              filters: {
                ...old.filters,
                take: newPageSize,
                skip: 0,
                page: 0,
              },
            }));
            setUpdateData(Math.random());
          }}
          onPageChange={(newPage) => {
            props.setData((old) => ({
              ...old,
              filters: {
                ...old.filters,
                skip: newPage * props?.data?.filters?.take,
                page: newPage,
              },
            }));
            setUpdateData(Math.random());
          }}
        />
      </CardContent>
    </Card>
  );
};
