import { deleteData, getData, postData, putData } from "..";

export const getApplecantStages = async () => {
  try {
    const response = await getData(`/admin/applicant-stage`);
    return response;
  } catch (error) {
    console.error(error); // Handle the error
  }
};

export const deleteApplecantStage = async (id) => {
  try {
    const response = await deleteData(`/admin/applicant-stage/${id}`);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const postApplicantStage = async (data) => {
  try {
    const request = await postData(`/admin/applicant-stage`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error);
  }
};

export const putApplicantStage = async (id, data) => {
  try {
    const request = await putData(`/admin/applicant-stage/${id}`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error);
  }
};
