import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { openModalChangePassword } from "../../../recoil/modal";
import {
  dataClickItem,
  methodRequestStatus,
  updateDataState,
} from "../../../recoil/global";
import { selectedJobFieldState } from "../../../recoil/select";
import { PostChangePassword } from "../../../services/api/ChangePassword";
import GeneralModal from "../../GeneralModal/GeneralModal";
import LockIcon from "@mui/icons-material/Lock";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Cookies from "js-cookie";
import axios from "axios";
const ModalChangePassword = () => {
  const [open, setOpen] = useRecoilState(openModalChangePassword);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const togglePassword = () => setShowPassword(!showPassword);
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const infoData = {
      password: data.get("password"),
    };
    const newPassword = data.get("new_password");
    const confirmPassword = data.get("password");
    if (newPassword == confirmPassword) {
      PostChangePassword(infoData)
        .then((res) => {
          if (res.status < 300) {
            console.log(res);
            Cookies.set("token", res.token);
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + Cookies.get("token");
            toast.success(res?.message);
            setPasswordError(false);
            handleClose();
          }
        })
        .catch((err) => {
          toast.error(<div>{err.response?.data?.errors[0]}</div>);
        });
    } else {
      setPasswordError(true);
    }
  };
  return (
    <GeneralModal
      maxWidth="md"
      open={open}
      onSubmit={handleSubmit}
      changePassword={true}
    >
      <Box sx={{ mx: 2, my: 2 }}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                fullWidth
                required
                error={passwordError}
                name="new_password"
                label="New password"
                type="password"
                id="new_password"
                autoComplete="current-password"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                fullWidth
                required
                error={passwordError}
                name="password"
                label="Confirm password"
                type="password"
                id="password"
                autoComplete="confirmPassword"
                helperText={passwordError && "Passwords do not match"}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </GeneralModal>
  );
};

export default ModalChangePassword;
