import React, { useEffect, useState } from "react";
import { FormControl, TextField, Autocomplete } from "@mui/material";
import { useRecoilState } from "recoil";
import { GetEmployerData } from "../../services/api/employer";
import { dataClickItem } from "../../recoil/global";
import { dataSelectCompanyState } from "../../recoil/select";

const SelectCompany = (props) => {
  const [dataSelectCompany, setDataSelectCompany] = useRecoilState(
    dataSelectCompanyState
  );
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataItem, setDataItem] = useRecoilState(dataClickItem);

  const handleChange = (event, newValue) => {
    // Save the selected value to the dataSelectCompany state
    setDataSelectCompany(newValue);
  };

  const handleInputChange = (event, newInputValue) => {
    // Store the input text in the state
    setSearch(newInputValue);
  };

  useEffect(() => {
    // Fetch data when the search term changes
    setLoading(true);
    GetEmployerData(0, 10000, search).then((data) => {
      setData(data.data);
      setLoading(false);
    });
  }, [search]);

  useEffect(() => {
    if (dataItem) {
      let data = dataItem.company;

      setDataSelectCompany({
        ...data,
        company_id: dataItem.company_id ? dataItem.company_id : "",
      });
    }
  }, [dataItem]);

  return (
    <FormControl fullWidth margin="normal">
      <Autocomplete
        sx={{ minWidth: 300 }}
        size="small"
        disablePortal
        id="combo-box-demo"
        options={data || []}
        getOptionLabel={(option) => option.company_name || ""}
        value={dataSelectCompany}
        onChange={handleChange} // Update the dataSelectCompany state when an item is selected
        onInputChange={handleInputChange}
        loading={loading}
        renderInput={(params) => (
          <TextField {...params} label="Company" required={props.req} />
        )}
      />
    </FormControl>
  );
};

export default SelectCompany;
