import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
  IconButton,
  Grid,
  TextField,
  Box,
} from "@mui/material";
import { IoIosAddCircleOutline } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useRecoilState, useSetRecoilState } from "recoil";
import { dataTabelState } from "../../recoil/Tabel";
import { openModalInfo } from "../../recoil/modal";
import {
  dataClickItem,
  methodRequestStatus,
  openFilterState,
} from "../../recoil/global";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

export const HeaderGridTabel = (props) => {
  const [dataState, setDataState] = useRecoilState(dataTabelState);
  const setOpenAdd = useSetRecoilState(openModalInfo);
  const setDataItem = useSetRecoilState(dataClickItem);
  const setMethodRequest = useSetRecoilState(methodRequestStatus);
  const setOpenFilter = useSetRecoilState(openFilterState);
  const mobile = useMediaQuery("(max-width:576px)");
  const sizes = () => {
    if (mobile) return "small";
    return "medium";
  };
  // function add
  const handleAdd = () => {
    setOpenAdd(true);
    setDataItem(null);
    setMethodRequest("add");
  };

  const borders_shadow = {
    borderRadius: "12px",
    boxShadow:
      "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
  };
  return (
    <Card
      elevation={0}
      sx={{
        ...borders_shadow,
      }}
    >
      <CardHeader
        avatar={props.icon}
        title={
          <Typography
            variant="h6"
            fontWeight="bold"
            color="primary"
            sx={{ fontSize: { xs: "0.9rem", sm: "1.25rem" } }}
          >
            {props.title}
          </Typography>
        }
        action={
          props.btn && (
            <Button
              variant="contained"
              startIcon={<IoIosAddCircleOutline size={20} />}
              size={sizes()}
              sx={{
                textTransform: "capitalize",
              }}
              onClick={() => handleAdd()}
              color="primary"
            >
              {props.btn}
            </Button>
          )
        }
      />
      <CardContent>
        <Grid container columnSpacing={1} rowSpacing={2} sx={{ px: 1 }}>
          <Grid item xs={12} sm={"auto"}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                value={dataState.filters.search}
                sx={{
                  borderColor: "#fff",
                  borderRadius: "12px",
                }}
                fullWidth
                onChange={(e) => {
                  setDataState((old) => ({
                    ...old,
                    filters: {
                      ...old.filters,
                      search: e.target.value,
                    },
                  }));
                }}
                size="small"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    props.fetchData();
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => {
                        props.fetchData();
                      }}
                    >
                      <AiOutlineSearch />
                    </IconButton>
                  ),
                }}
                placeholder="Search"
                label="Search"
              />
              {props.isFilter && (
                <IconButton
                  onClick={() => setOpenFilter({ right: true })}
                  aria-label="delete"
                  size="large"
                >
                  <FilterAltIcon fontSize="inherit" />
                </IconButton>
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
