import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
export const ColumnButton = (props) => {
  return (
    <Box>
      {props.type === "edit" ? (
        <Tooltip title="Edit">
          <IconButton color="warning" onClick={props?.onClick}>
            <AiOutlineEdit />
          </IconButton>
        </Tooltip>
      ) : props.type === "delete" ? (
        <Tooltip title="Delete">
          <IconButton color="error" onClick={props?.onClick}>
            <AiOutlineDelete />
          </IconButton>
        </Tooltip>
      ) : props.type === "loading" ? (
        <Tooltip title="Loading">
          <IconButton color="warning" onClick={props?.onClick}>
            <HourglassBottomIcon />
          </IconButton>
        </Tooltip>
      ) : props.type === "add" ? (
        <Tooltip title="Add">
          <IconButton color={"primary"} onClick={props?.onClick}>
            <AddCircleIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={props.title}>
          <IconButton color={props.color} onClick={props?.onClick}>
            {props.icon}
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};
